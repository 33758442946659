import { cn } from "@/utils/ui";
import { formatCurrency } from "@progresspay-next/shared";
import { cva } from "class-variance-authority";
import {
  animate,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { useEffect, useState } from "react";
import { Skeleton } from "../ui/skeleton";

type GraphicSummaryProps = {
  heading: React.ReactNode;
  icon: React.ReactNode;
  content: number;
  isCurrency?: boolean;
  isAnimated?: boolean;
  isHighlighted?: boolean;
  iconColour?: "gray" | "pink" | "yellow" | "green" | "blue";
  isLoading?: boolean;
};
const iconVariants = cva("", {
  variants: {
    iconColour: {
      gray: [`text-gray-500`, `bg-gray-300`],
      pink: [`text-pink-600`, `bg-pink-200`],
      yellow: [`text-yellow-600`, `bg-yellow-100`],
      green: [`text-green-600`, `bg-green-200`],
      blue: [`text-blue-600`, `bg-blue-200`],
    },
  },
  defaultVariants: {
    iconColour: `gray`,
  },
});

export const GraphicSummary = (props: GraphicSummaryProps) => {
  const {
    isCurrency = false,
    isAnimated = false,
    iconColour,
    isHighlighted = false,
    isLoading = false,
  } = props;
  const spring = useSpring(0);
  const [animatedContent, setAnimatedContent] = useState("");
  useEffect(() => {
    animate(spring, props.content, {
      duration: 0.5,
      onUpdate: (lastest) =>
        setAnimatedContent(
          isCurrency ? formatCurrency(lastest) : lastest.toFixed(0)
        ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);
  return (
    <div
      className={cn(
        "p-4 rounded-xl bg-white w-full border border-gray-200",
        isHighlighted && "ring-1"
      )}
    >
      <div className="flex flex-row items-center">
        <div className={cn("rounded-full p-3", iconVariants({ iconColour }))}>
          <div className="w-6 h-6">{props.icon}</div>
        </div>
        <div className="text-gray-500 px-2 text-lg">{props.heading}</div>
      </div>
      <div className="font-bold text-3xl pt-3">
        {isLoading ? (
          <Skeleton className="w-full h-9 rounded-xl" />
        ) : (
          animatedContent
        )}
      </div>
    </div>
  );
};
