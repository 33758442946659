import {
  me,
  createUser,
  inviteUser,
  updateUser,
  findUsers,
  updateUsers,
  findUser,
  findUserNonAdmin,
  findUsersNonAdmin,
  acceptTerms,
} from "./api/users";

import { authUser, forgotPassword, resetPassword, validateInviteToken } from "./api/auth";

import {
  getInvoiceTransactionData,
} from "./api/integrations";

import {
  createOrganisation,
  updateOrganisation,
  updateOrganisationNonAdmin,
  findOrganisation,
  findOrganisations,
  deleteOrganisation,
  importOrgProject,
  findOrgContracts,
  findOrgSubcontractors,
} from "./api/organisations";

import {
  findInvoice,
  findInvoices,
  importInvoice,
  updateInvoice,
  findInvoicesNonAdmin,
  findInvoiceNonAdmin,
  offerEarlyPayment,
  requestEarlyPayment,
  updateEarlyPayment,
  cancelEarlyPayment,
  approveEarlyPayment,
  confirmEarlyPayment,
  findInvoiceActivities,
  createAPTransaction,
  updatePaymentDate,
  updatePaymentDateCheops,
  createNewPMWorksheet,
  rejectEarlyPayment,
  expireInvoice,
  invoicesSummaryNonAdmin,
  findEligibleInvoicesNonAdmin,
  findEligibleInvoiceNonAdmin,
  requestEarlyPaymentOnEligibleInvoice
} from "./api/invoices";

import {
  getAttachmentUrlById
} from "./api/attachments"

import {
  findProjectsNonAdmin,
  disableProjectNonAdmin,
  enableProjectNonAdmin,
  importProjectNonAdmin,
} from "./api/projects";

import {
  findExclusionsByOrganisationId,
  createExclusion,
  deleteExclusionById
} from "./api/exclusions"

import { decodeInviteToken } from "./api/misc";

export const getApi = () => {
  return {
    me,
    createUser,
    updateUser,
    findUsers,
    inviteUser,
    updateUsers,
    findUser,
    findUserNonAdmin,
    findUsersNonAdmin,
    acceptTerms,

    getInvoiceTransactionData,

    authUser,
    forgotPassword,
    resetPassword,
    validateInviteToken,

    createOrganisation,
    updateOrganisation,
    updateOrganisationNonAdmin,
    findOrganisation,
    findOrganisations,
    deleteOrganisation,
    importOrgProject,
    findOrgContracts,
    findOrgSubcontractors,

    findInvoice,
    findInvoiceNonAdmin,
    findInvoices,
    findInvoicesNonAdmin,
    importInvoice,
    updateInvoice,
    offerEarlyPayment,
    requestEarlyPayment,
    updateEarlyPayment,
    approveEarlyPayment,
    confirmEarlyPayment,
    rejectEarlyPayment,
    cancelEarlyPayment,
    findInvoiceActivities,
    expireInvoice,
    invoicesSummaryNonAdmin,
    findEligibleInvoicesNonAdmin,
    findEligibleInvoiceNonAdmin,
    requestEarlyPaymentOnEligibleInvoice,

    decodeInviteToken,

    getAttachmentUrlById,

    createAPTransaction,
    updatePaymentDate,
    createNewPMWorksheet,

    updatePaymentDateCheops,

    findProjectsNonAdmin,
    disableProjectNonAdmin,
    enableProjectNonAdmin,
    importProjectNonAdmin,

    findExclusionsByOrganisationId,
    createExclusion,
    deleteExclusionById
  };
};
