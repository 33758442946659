import { ConfirmDialog } from "@/components/ui/confirm";
import { OrganisationType } from "@progresspay-next/dtos";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Button, Drawer, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import {
  PageTitleSlotType,
  useInternalLayoutContext,
} from "../../components/layouts/InternalLayout";
import { getApi } from "../../utils/api";
import { useQueryOrganisations } from "../../utils/query";
import { useColumnSearchProps } from "../../utils/tables";
import { useLocalUrlChange } from "../../utils/url";
import { OrganisationsForm } from "./OrganisationsForm";

interface OrganisationsListProps {}

type DataType = OrganisationType;

type DataIndex = keyof DataType;

const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
  {
    path: "/admin/organisations",
    breadcrumbName: "Organisations",
  },
];

const ConfirmDeleteOrganisationButton = ({
  onConfirm,
}: {
  onConfirm: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <a href="#" onClick={() => setIsOpen(true)}>
        Delete
      </a>
      <ConfirmDialog
        callback={() => onConfirm()}
        onOpenChange={setIsOpen}
        open={isOpen}
        title={`Are you sure to delete this organisation?`}
        cancelText="No"
        confirmText="Yes"
      ></ConfirmDialog>
    </>
  );
};

export const OrganisationsList: (
  props: OrganisationsListProps
) => JSX.Element | null = () => {
  const { message } = App.useApp();
  const [editTargetId, setEditTargetId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const api = getApi();
  const queryClient = useQueryClient();
  const [highlightTargetId, setHighlightTargetId] = useState(null);
  const [goLocal, backLocal] = useLocalUrlChange();

  const setPageTitleSlotProps =
    useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps &&
      setPageTitleSlotProps({
        type: PageTitleSlotType.SimplePageTitle,
        props: {
          title: "Organisations",
        },
      });
  }, [setPageTitleSlotProps]);

  const deleteOrganisation = useMutation({
    mutationFn: (id: string | number) => {
      return api.deleteOrganisation(id);
    },
    onSuccess: () => {
      message.success("The organisation has been deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
  });

  const organisations = useQueryOrganisations();
  const targetOrganisation = organisations.isSuccess
    ? [
        ...organisations.data,
        ...organisations.data.map((rootOrg) => rootOrg.children),
      ]
        .flat()
        .find((org) => org?.id == editTargetId)
    : null;

  const handleCreateNew = () => {
    setEditTargetId(null);
    let newUrl = new URL(window.location.href).pathname + "/new";
    goLocal(newUrl);
    setIsOpen(true);
  };
  const handleDrawerClose = () => {
    setIsOpen(false);
    backLocal();
    setHighlightTargetId(null);
  };
  const handleEditExisting = (id: any) => () => {
    setEditTargetId(id);
    let newUrl = new URL(window.location.href).pathname + "/edit/" + id;
    goLocal(newUrl);
    setIsOpen(true);
    setHighlightTargetId(id);
  };
  const handleDeleteExisting = (id: any) => () => {
    deleteOrganisation.mutate(id);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("name"),
    },
    {
      title: "ERP ID",
      dataIndex: "erp_id",
      key: "erp_id",
    },
    {
      title: "ERP ID 2",
      dataIndex: "erp_id_2",
      key: "erp_id_2",
    },
    {
      title: "PAS ID",
      dataIndex: "pas_id",
      key: "pas_id",
    },
    {
      title: "ABN",
      dataIndex: "abn",
      key: "abn",
    },
    {
      title: "ACN",
      dataIndex: "acn",
      key: "acn",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (_, { type }) => (
        <Tag color={type == "SC" ? "geekblue" : "green"}>{type}</Tag>
      ),
      filters: [
        {
          text: "SC",
          value: "SC",
        },
        {
          text: "GC",
          value: "GC",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value as string) === 0,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={handleEditExisting(record.id)}>Edit</a>
          <ConfirmDeleteOrganisationButton
            onConfirm={handleDeleteExisting(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        extra={
          <Button
            shape="round"
            key="1"
            type="primary"
            onClick={handleCreateNew}
          >
            Create New
          </Button>
        }
        routes={routes}
      ></PageHeader>
      <Table
        columns={columns}
        loading={organisations.isLoading}
        rowKey="id"
        size="small"
        pagination={{
          pageSize: 25,
        }}
        dataSource={
          organisations.isSuccess
            ? organisations.data.map((org) => ({
                ...org,
                children: org.children?.length ? org.children : null,
              }))
            : []
        }
        rowClassName={(record: OrganisationType) =>
          record.id == highlightTargetId ? "rowHighlighted" : ""
        }
      />
      <Drawer
        title={editTargetId ? `${targetOrganisation?.name}` : "Create"}
        placement="right"
        open={isOpen}
        onClose={handleDrawerClose}
        maskClosable={false}
        width={"75vw"}
      >
        {isOpen ? (
          <OrganisationsForm
            context="drawer"
            id={editTargetId}
            onActionSuccess={() => {
              setIsOpen(false);
            }}
          />
        ) : null}
      </Drawer>
    </>
  );
};
