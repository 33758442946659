import { StyledAccordion } from "@/components/StyledAccordion";
import autoAnimate from "@formkit/auto-animate";
import {
  CreateExclusionRequest,
  ExclusionEntityType,
  UpdateOrganisationsRequest,
} from "@progresspay-next/dtos";
import { constFees } from "@progresspay-next/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Button, Form, Input, InputNumber, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { Fragment, useEffect, useRef, useState } from "react";
import { getApi } from "../../utils/api";
import {
  queryKey,
  useQueryExclusionsByOrganisationId,
  useQueryOrganisationById,
} from "../../utils/query";

interface OrganisationsFormSettingsTabProps {
  id: number | string;
}

export const OrganisationBasicSettingsItems = () => {
  return (
    <>
      <div className="flex flex-row gap-4">
        <div className="flex-auto">
          <Form.Item
            label="ProgressPay Fee Percentage"
            name={["settings", "pp_fee"]}
            rules={[
              {
                required: true,
                message: "Please input your ProgressPay fee percentage!",
              },
            ]}
          >
            <InputNumber addonAfter="%" />
          </Form.Item>
        </div>
        <div className="flex-auto">
          <Form.Item
            label="Total Facility Limit"
            name={["settings", "facility_limit"]}
            rules={[
              {
                required: true,
                message: "Please input your total facility limit!",
              },
            ]}
          >
            <InputNumber addonBefore="$" />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export const ExclusionSettingForm = ({ id }: { id: number | string }) => {
  const allExclusions = useQueryExclusionsByOrganisationId(String(id));
  const api = getApi();
  const queryClient = useQueryClient();
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const [pasId, setPASId] = useState("");
  const [externalId, setExternalId] = useState("");

  const createExclusionMutation = useMutation({
    mutationFn: (payload: CreateExclusionRequest) => {
      return api.createExclusion(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.exclusionsByOrganisationId(id),
      });
      setPASId("");
      setExternalId("");
    },
  });
  const deleteExclusionMutation = useMutation({
    mutationFn: (id: string | number) => {
      return api.deleteExclusionById(String(id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.exclusionsByOrganisationId(id),
      });
    },
  });
  const handleDeleteExclusion = (id: string | number) => {
    deleteExclusionMutation.mutate(id);
  };
  const handleCreateExclusion = () => {
    createExclusionMutation.mutate({
      pas_id: String(pasId),
      erp_id: externalId,
      organisation_id: String(id),
      entity_type: ExclusionEntityType.organisations,
    });
  };

  const isNewExclusionValid = () => {
    return pasId !== "" || externalId !== "";
  };

  return allExclusions.isSuccess ? (
    <>
      <div>
        <p>
          Add a <i>Payapps Organisation ID</i> or <i>Jobpac Vendor ID</i> to
          exclude an organisation/vendor from the ProgressPay program.
        </p>
        <br />
        <div ref={parent} className="flex flex-row flex-wrap gap-4">
          <div className="w-2/5 p-1 font-bold">Payapps Organisation ID</div>
          <div className="w-2/5 p-1 font-bold">Jobpac Vendor ID</div>
          <div className="flex-1 p-1 font-bold"></div>
          {allExclusions.data.map((exclusion, eIndex) => (
            <Fragment key={exclusion.id}>
              <div className="w-2/5 px-1">
                <Input readOnly value={exclusion.pas_id ?? ""} />
              </div>
              <div className="w-2/5 px-1">
                <Input readOnly value={exclusion.erp_id ?? ""} />
              </div>
              <div className="flex-1 px-1">
                <Button
                  danger
                  onClick={() => handleDeleteExclusion(exclusion.id)}
                >
                  delete
                </Button>
              </div>
            </Fragment>
          ))}
          <div className="w-2/5 p-1">
            <Input
              placeholder="Payapps Organisation ID"
              value={pasId}
              onChange={(e) => setPASId(e.target.value)}
            />
          </div>
          <div className="w-2/5 p-1">
            <Input
              placeholder="Jobpac Vendor ID"
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
            />
          </div>
          <div className="flex-1 p-1">
            <Button
              type="primary"
              disabled={!isNewExclusionValid()}
              onClick={handleCreateExclusion}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Spin />
  );
};

export const OrganisationsFormSettingsTab: (
  props: OrganisationsFormSettingsTabProps
) => JSX.Element | null = ({ id }) => {
  const { message } = App.useApp();
  const [formBasic] = useForm();
  const api = getApi();
  const queryClient = useQueryClient();
  const updateOrganisation = useMutation({
    mutationFn: (organisation: UpdateOrganisationsRequest) => {
      return api.updateOrganisation(organisation);
    },
    onSuccess: (data, variables, context) => {
      message.success(
        "Your organisation settings have been updated successfully."
      );
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
  });
  const handleSubmit = (v: {
    settings: {
      pp_fee: number;
      facility_limit: number;
    };
  }) => {
    id = String(id);

    updateOrganisation.mutate({
      id,
      settings: {
        pp_fee: v.settings.pp_fee,
        facility_limit: v.settings.facility_limit,
      },
    });
  };
  const organisationDetails = useQueryOrganisationById(id);

  return (
    <>
      {organisationDetails.isSuccess ? (
        <>
          <StyledAccordion
            type="multiple"
            defaultKey={[`basicSettings`]}
            items={[
              {
                key: "basicSettings",
                heading: `Basic Settings`,
                content: (
                  <Form
                    form={formBasic}
                    layout={"vertical"}
                    initialValues={{
                      settings: {
                        pp_fee: organisationDetails.data.settings?.pp_fee ?? constFees.defaultProgressPayFeePercent,
                        facility_limit:
                          organisationDetails.data.settings?.facility_limit ??
                          0,
                      },
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                  >
                    <OrganisationBasicSettingsItems />
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
              {
                key: "facilityLimitExclusions",
                heading: `Facility Exclusions`,
                content: <ExclusionSettingForm id={id} />,
              },
            ]}
          ></StyledAccordion>
          <div></div>
        </>
      ) : (
        <Spin />
      )}
    </>
  );
};
