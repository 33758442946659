"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EligibleInvoiceSchema = exports.EligibleInvoiceFromPayappsDetailSchema = exports.InvoiceTypeSchema = void 0;
const zod_1 = require("zod");
const contract_type_1 = require("./contract.type");
const organisation_type_1 = require("./organisation.type");
const calculatorSchema = zod_1.z.object({
    originalAmount: zod_1.z.number(),
    discountAmountInclTax: zod_1.z.number(),
    discountAmountExclTax: zod_1.z.number(),
    discountTaxAmount: zod_1.z.number(),
    discountAmountPercentage: zod_1.z.number().nullable(),
    revisedAmountInclTax: zod_1.z.number(),
    revisedAmountExclTax: zod_1.z.number(),
    revisedAmountTax: zod_1.z.number(),
    revisedAmountPercentage: zod_1.z.number(),
    revisedDueDate: zod_1.z.string().nullable(),
    progressPayFeeExclTaxAmount: zod_1.z.number(),
    progressPayFeeTaxAmount: zod_1.z.number(),
    progressPayFeeInclTaxAmount: zod_1.z.number(),
    progressPayFeePercentage: zod_1.z.number(),
    pmwAmount: zod_1.z.number(),
});
// Version 1
// We snaphot the discounts configuration and days difference at the time of early payment request
const DiscountConfigurationSnapshotSchemaVersion1 = zod_1.z.object({
    version: zod_1.z.literal(1),
    organisation_discounts_config: organisation_type_1.OrganisationTypeSchema.shape.discounts,
    days_difference: zod_1.z.number(),
    payment_terms: zod_1.z.any(),
});
const DiscountConfigurationSnapshotSchema = zod_1.z.discriminatedUnion('version', [
    DiscountConfigurationSnapshotSchemaVersion1,
]);
exports.InvoiceTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    contract_id: zod_1.z.string(),
    erp_id: zod_1.z.string().nullable(),
    pas_id: zod_1.z.string().nullable(),
    addendum_attachment_id: zod_1.z.string().nullable(),
    contract: contract_type_1.ContractTypeSchema.optional(),
    activities: zod_1.z.any().array().nullable(),
    original_payment_due_date: zod_1.z.string().nullable(),
    revised_payment_due_date: zod_1.z.string().nullable(),
    source: zod_1.z.any().nullable(),
    invoice_status: zod_1.z.string(),
    discount: zod_1.z
        .object({
        payrun_date: zod_1.z.string(),
        discount: zod_1.z.number(),
    })
        .nullable(),
    discounts_snapshot: DiscountConfigurationSnapshotSchema.nullable(),
    created_at: zod_1.z.string().nullable(),
    updated_at: zod_1.z.string().nullable(),
    expired_at: zod_1.z.string().nullable(),
    sc_user: zod_1.z.any().nullable(),
    external_data: zod_1.z.any().nullable(),
    integrations: zod_1.z.any().nullable(),
    requested_at: zod_1.z.string().nullable(),
    approved_at: zod_1.z.string().nullable(),
    confirmed_at: zod_1.z.string().nullable(),
    terms_agreed_at: zod_1.z.string().nullable(),
    confirmation_required: zod_1.z.boolean(),
    requested_prior_pas_approval: zod_1.z.boolean(),
    can_approve: zod_1.z.boolean().nullable(),
    is_posted_claim: zod_1.z.boolean().nullable(),
    valid_cost_centre: zod_1.z.boolean().nullable(),
    metadata: zod_1.z.any().nullable().optional(),
    calculator: calculatorSchema.nullable(),
});
// Schema for the EligibleInvoice coming from Payapps when returned in a single object
exports.EligibleInvoiceFromPayappsDetailSchema = zod_1.z.object({
    counterparties: zod_1.z.object({
        claimant: zod_1.z.any(),
        respondent: zod_1.z.any(),
    }),
    contract: zod_1.z.any(),
    project: zod_1.z.any(),
    claim: zod_1.z.any(),
    users: zod_1.z.any(),
    user: zod_1.z.any(),
});
exports.EligibleInvoiceSchema = zod_1.z.object({
    gcName: zod_1.z.string().nullable(),
    scName: zod_1.z.string().nullable(),
    scABN: zod_1.z.string().nullable(),
    discounts: organisation_type_1.OrganisationTypeSchema.shape.discounts.nullish(),
    project: zod_1.z.string().nullable(),
    contract: zod_1.z.string().nullable(),
    pasId: zod_1.z.string(),
    invoiceNumber: zod_1.z.string().nullable(),
    claimNumber: zod_1.z.string().nullable(),
    amountIncludingGST: zod_1.z.number().nullable(),
    originalDueDate: zod_1.z.string().nullable(),
    status: zod_1.z.string().nullable(),
    attachments: zod_1.z
        .object({
        name: zod_1.z.string(),
        url: zod_1.z.string(),
        size: zod_1.z.number().nullable(),
    })
        .array()
        .nullable(),
});
