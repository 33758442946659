import { Button } from "./button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";

export const ConfirmDialog = ({
  open,
  onOpenChange,
  callback,
  title = "Are you sure?",
  children = "",
  confirmText = "Confirm",
  cancelText = "Cancel",
}: {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  callback: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(v) => {
        onOpenChange(v);
      }}
      
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl">{title}</DialogTitle>
        </DialogHeader>
        <div tabIndex={0}>
        {children}
        </div>
        <DialogFooter>
          <DialogTrigger asChild>
            <Button variant={"outline"}>{cancelText}</Button>
          </DialogTrigger>
          <Button
            onClick={() => {
              callback();
              onOpenChange(false);
            }}
          >
            {confirmText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
