import { useContextStore } from "@/stores/context";
import { useDashboardFilterStore } from "@/stores/dashboardFilter";
import { useInvoiceActionStore } from "@/stores/invoiceAction";
import { useRememberFilterModel } from "@/utils/ag-grid";
import { useQueryInvoicesNonAdmin } from "@/utils/query";
import autoAnimate from "@formkit/auto-animate";
import { InvoiceResponse, InvoiceStatusTypes } from "@progresspay-next/dtos";
import {
  formatCurrency,
  invoiceHelper,
  momentjsFormat,
} from "@progresspay-next/shared";
import {
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronRightIcon,
  LucideLoader,
  XIcon,
} from "lucide-react";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Pills } from "../ag-grid-filters/Pills";
import { EarlyPaymentRequestDrawerWithButton } from "../invoices/EarlyPaymentRequestDrawerWithButton";
import { InvoiceStatusTag } from "../invoices/InvoiceStatusTag";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { ChangeTabValueContext } from "./Dashboard";

// This alert is used to let user know that the target invoice will be moved to another tab
const InvoiceActionAlert = () => {
  const [show, setShow] = useState(false);
  const { userActionTargetId, clearAll } = useInvoiceActionStore();
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);
  useEffect(() => {
    if (userActionTargetId) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [setShow, userActionTargetId]);
  const setTabValue = useContext(ChangeTabValueContext);
  return (
    <div ref={parent}>
      {show ? (
        <div className="mb-2">
          <Alert variant={"success"}>
            <CheckCircleIcon className="h-4 w-4" />
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>
              <p>
                The early payment request has been approved. The counterparty
                will be notified.
              </p>
              <p>
                In the future, you can find it in the{" "}
                <a
                  className="underline font-bold"
                  onClick={() => {
                    setTabValue("approved");
                  }}
                >
                  Completed
                </a>{" "}
                tab.
              </p>
            </AlertDescription>
          </Alert>
          <Button
            className="right-4 top-4 absolute"
            variant={"ghost"}
            onClick={() => clearAll()}
          >
            <XIcon className="h-4 w-4" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const ActionsCellRenderer = (props: ICellRendererParams) => {
  const record = props.data;
  const { isEmbedded } = useContextStore();
  const { userActionTargetId } = useInvoiceActionStore();
  const isRowLastActioned = !!(
    userActionTargetId && record.id === userActionTargetId
  );
  return record.id ? (
    <>
      {isRowLastActioned ? (
        <div className="flex justify-center w-full h-full items-center">
          <CheckIcon className="h-4 w-4 stroke-[4] text-green-400" />
        </div>
      ) : null}
      <div hidden={isRowLastActioned}>
        {isEmbedded ? (
          <Button variant={"ghost"}>
            <Link className="p-2" to={`/embed/invoices/${record.id}`}>
              <ChevronRightIcon className="h-4 w-4 stroke-[4]" />
            </Link>
          </Button>
        ) : (
          <EarlyPaymentRequestDrawerWithButton invoice={record} />
        )}
      </div>
    </>
  ) : null;
};
const InvoiceStatusCellRenderer = (props: ICellRendererParams) => {
  return (
    <div className="px-[1px]">
      <InvoiceStatusTag status={props.data.invoice_status.toLowerCase()} />
    </div>
  );
};

type InvoicesTableProps = {
  invoices: InvoiceResponse[]; // TODO: Better typing
  inProgressOnly?: boolean;
};
const Table = ({ invoices, inProgressOnly = false }: InvoicesTableProps) => {
  const agGridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState(invoices);
  useEffect(() => setRowData(invoices), [invoices]);
  const { userActionTargetId } = useInvoiceActionStore();

  const { me } = useContextStore();

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState<
    (ColDef<InvoiceResponse> | ColGroupDef<InvoiceResponse>)[]
  >([
    {
      field: "created_at",
      headerName: "Requested At",
      type: [`dateCol`, `withDateFilterCol`],
    },
    {
      field: "external_data.claim_number",
      headerName: "Claim #",
      type: ["withTextFilterCol"],
    },
    {
      field: "external_data.claim_status",
      headerName: "Payapps Status",
      type: ["withTextFilterCol"],
    },
    {
      field: "invoice_status",
      headerName: "Early Payment Status",
      type: ["withTextFilterCol"],
      cellRenderer: InvoiceStatusCellRenderer,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        const sortMap: Record<string, number> = {
          ELIGIBLE: 11,
          INELIGIBLE: 10,
          INTENDED: 20,
          AVAILABLE: 30,
          REQUESTED: 40,
          APPROVED: 50,
          REJECTED: 51,
          CANCELLED: 52,
          PAID: 60,
        };
        return (
          (sortMap[valueA] ? sortMap[valueA] : 0) -
          (sortMap[valueB] ? sortMap[valueB] : 0)
        );
      },
    },
    me?.is_gc
      ? {
          headerName: "Subcontractor",
          field: "contract.sc_organisation.name",
          type: ["withTextFilterCol"],
        }
      : {
          headerName: "General Contractor",
          field: "contract.organisation.name",
          type: ["withTextFilterCol"],
        },
    {
      headerName: "Original Due Date",
      valueGetter: ({ getValue, data }) => {
        if (!data) {
          return "";
        }
        return invoiceHelper.isOriginalDueDateApplicable(data)
          ? moment(data.original_payment_due_date).format(momentjsFormat.date)
          : "Standard terms"; // workaround for several things
      },
      filterValueGetter: ({ data }: ValueGetterParams) => {
        if (!data) {
          return null;
        }
        return invoiceHelper.isOriginalDueDateApplicable(data)
          ? moment(data.original_payment_due_date).toDate()
          : null;
      },
      type: [`withDateFilterCol`],
    },
    {
      field: "external_data.net_claim_incl_tax",
      headerName: "Invoice Amount",
      type: ["currencyCol", "numericColumn"],
    },
    {
      field: "external_data.pas_ref",
      headerName: "Invoice #",
      type: ["withTextFilterCol"],
      width: 230,
      pinned: "left",
    },
    inProgressOnly
      ? {
          field: "discount.payrun_date",
          headerName: "Requested Due Date",
          type: [`dateCol`, "withDateFilterCol"],
        }
      : {
          field: "revised_payment_due_date",
          headerName: "New Due Date",
          type: [`dateCol`, "withDateFilterCol"],
        },
    {
      colId: "actions",
      headerName: "",
      cellRenderer: ActionsCellRenderer,
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: { textAlign: "center" },
      resizable: false,
      pinned: "right",
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    []
  );

  const customColTypes = useMemo(
    () => ({
      currencyCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceResponse>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return formatCurrency(params.value);
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      dateTimeCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceResponse>) => {
          if (
            params.value === null ||
            params.value === undefined ||
            params.value === ""
          ) {
            return "";
          }
          return moment(params.value).format(momentjsFormat.dateTime);
        },
      },
      dateCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceResponse>) => {
          if (
            params.value === null ||
            params.value === undefined ||
            params.value === ""
          ) {
            return "";
          }
          return moment(params.value).format(momentjsFormat.date);
        },
      },
      percentageCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceResponse>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return `${params.value.toFixed(2)}%`;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      withTextFilterCol: {
        filter: true,
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "blank",
            "notBlank",
          ],
          buttons: ["reset"],
        },
      },
      withDateFilterCol: {
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["reset"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: any) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
              return 0;
            }

            const cellDate = moment(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate.isBefore(moment(filterLocalDateAtMidnight), "days")) {
              return -1;
            } else if (
              cellDate.isAfter(moment(filterLocalDateAtMidnight), "days")
            ) {
              return 1;
            }
            return 0;
          },
        },
      },
    }),
    []
  );

  const { getFilterModel, setFilterModel } = useRememberFilterModel(
    "dashboard-eligible-invoices"
  );

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const allCols = e.api.getColumns();
      if (allCols) {
        e.api.sizeColumnsToFit();
      }
      // Resume filters in the localstorage
      if (getFilterModel()) {
        e.api.setFilterModel(getFilterModel());
      }
    },
    [getFilterModel]
  );

  const [filterPills, setFilterPills] = useState<
    { key: string; text: string }[]
  >([]);

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel();
      const all = [];
      for (let colKey in filterModel) {
        const col = e.columnApi.getColumn(colKey);
        if (col) {
          all.push({
            key: col.getColId(),
            text: `Filter: ${col.getColDef().headerName || colKey}`,
          });
        }
      }
      setFilterPills(all);
      // Store filters in the localstorage
      setFilterModel(filterModel);
    },
    [setFilterModel]
  );

  const onFilterRemoved = (key: string) => {
    const gridApi = agGridRef.current!.api;
    const currentModel = gridApi.getFilterModel();
    delete currentModel[key];
    gridApi.setFilterModel(currentModel);
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<InvoiceResponse>) => params.data.id;
  }, []);

  return (
    <div>
      <InvoiceActionAlert />
      <div className="mb-2 flex flex-row flex-nowrap justify-between items-center">
        <div className="flex-1">
          <Input
            className="max-w-[400px]"
            placeholder="Quick search..."
            onChange={(e) => {
              agGridRef.current?.api.setQuickFilter(e.target.value);
            }}
          ></Input>
        </div>
        <div className="flex-0"></div>
      </div>
      <div>
        <Pills filters={filterPills} onFilterRemoved={onFilterRemoved}></Pills>
      </div>
      <div className="ag-theme-alpine w-full h-[calc(100vh-380px)]">
        <AgGridReact
          ref={agGridRef}
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          columnTypes={customColTypes}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          suppressColumnVirtualisation // This is to resize cols not in viewport yet (with a performance cost)
          getRowId={getRowId}
          suppressServerSideFullWidthLoadingRow
        />
      </div>
    </div>
  );
};
export const RequestedInvoices = ({
  completedOnly = false,
  inProgressOnly = false,
  applyDashboardFilter = false,
}: {
  completedOnly?: boolean;
  inProgressOnly?: boolean;
  applyDashboardFilter?: boolean;
}) => {
  const dashboardFilterStore = useDashboardFilterStore();
  const invoicesQuery = useQueryInvoicesNonAdmin(
    applyDashboardFilter ? dashboardFilterStore.getFilterQuery() : {}
  );
  const { userActionTargetId } = useInvoiceActionStore();
  let invoices = invoicesQuery.data || [];
  if (completedOnly) {
    invoices = invoices.filter(
      (invoice) =>
        invoice.invoice_status === InvoiceStatusTypes.APPROVED ||
        invoice.invoice_status === InvoiceStatusTypes.PAID ||
        invoice.invoice_status === InvoiceStatusTypes.REJECTED ||
        invoice.invoice_status === InvoiceStatusTypes.CANCELLED
    );
  }
  if (inProgressOnly) {
    invoices = invoices.filter(
      (invoice) =>
        (invoice.invoice_status !== InvoiceStatusTypes.APPROVED &&
          invoice.invoice_status !== InvoiceStatusTypes.PAID &&
          invoice.invoice_status !== InvoiceStatusTypes.REJECTED &&
          invoice.invoice_status !== InvoiceStatusTypes.CANCELLED) ||
        invoice.id === userActionTargetId
    );
  }
  return invoicesQuery.isLoading ? (
    <div className="w-full h-40 rounded-xl flex flex-row justify-center items-center">
      <LucideLoader className="h-6 w-6 animate-spin" />
    </div>
  ) : (
    <Table invoices={invoices} inProgressOnly={inProgressOnly} />
  );
};
