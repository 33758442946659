import { InvoicesTable } from "@/components/invoices/InvoicesTable";
import { InvoiceType } from "@progresspay-next/dtos";
import { Layout } from "antd";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import {
    PageTitleSlotType,
    useInternalLayoutContext
} from "../../components/layouts/InternalLayout";
import { useContextStore } from "../../stores/context";
import { useQueryInvoices } from "../../utils/query";
const { Header, Content, Footer } = Layout;

const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
  {
    path: "/admin/invoices",
    breadcrumbName: "Invoices",
  },
];

export const Invoices = () => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "Invoices",
      },
    });
  }, [setPageTitleSlotProps]);
  const contextStore = useContextStore();
  const [isShowingExpired, setIsShowingExpired] = useState<boolean>(false);
  const invoices = useQueryInvoices({
    include_expired: isShowingExpired,
  });
  const isLoading = !contextStore.me || invoices.isLoading;
  return (
    <>
      <PageHeader routes={routes}></PageHeader>
      {isLoading ? null : (
        <>
          <InvoicesTable
            invoices={invoices.data as InvoiceType[]}
            isGC={
              (contextStore.me as NonNullable<typeof contextStore.me>).is_gc
            }
            isSysAdmin={
              (contextStore.me as NonNullable<typeof contextStore.me>)
                .is_system_admin
            }
            initialIsExpiredIncluded={isShowingExpired}
            onInvoicesEvent={(eventName, payload) => {
              if (eventName === "IS_EXPIRED_INCLUDED_CHANGED") {
                setIsShowingExpired(payload);
              }
            }}
          />
        </>
      )}
    </>
  );
};
