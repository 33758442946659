import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ForgotPasswordRequest } from "@progresspay-next/dtos";
import { useMutation } from "@tanstack/react-query";
import {
  App,
  Button,
  Form,
  Input,
  Typography
} from "antd";
import { CheckCircleIcon } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalStore } from "../stores/global";
import { getApi } from "../utils/api";

interface ForgotPasswordProps {}

export const ForgotPassword: (
  props: ForgotPasswordProps
) => JSX.Element | null = () => {
  const { message } = App.useApp();
  const store = useGlobalStore();
  const navigate = useNavigate();
  const api = getApi();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const forgotPassword = useMutation({
    mutationFn: (payload: ForgotPasswordRequest) => {
      return api.forgotPassword(payload);
    },
    onSuccess: (data, variables, context) => {
      // TODO: resend / success mesage
      message.success(
        "If an account is associated with this email address, password reset instructions have been sent."
      );
      setIsSuccess(true);
    },
  });

  const onFinish = (values: any) => {
    forgotPassword.mutate(values);
  };

  const [containerRef] = useAutoAnimate();

  return (
    <>
      <div className="mb-8 flex items-center gap-4">
        <span className="text-3xl font-bold">Forgot Password?</span>
      </div>
      <Form
        className="w-full"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid emaill!" },
          ]}
        >
          <Input />
        </Form.Item>

        {forgotPassword.isError ? (
          <Form.Item>
            <Typography.Paragraph type={"danger"}>
              An error occurred: {(forgotPassword.error as Error).message}
            </Typography.Paragraph>
          </Form.Item>
        ) : null}
        <div ref={containerRef}>
          {isSuccess ? (
            <div className="flex gap-3 bg-green-50 p-4 rounded-md">
              <CheckCircleIcon className="text-green-500 w-6 h-6" />
              If an account is associated with this email address, password
              reset instructions have been sent.
            </div>
          ) : null}
        </div>
        <div className="flex gap-2 mt-8 items-center">
          {isSuccess ? null : (
            <Button
              size="large"
              loading={forgotPassword.isPending}
              disabled={isSuccess}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
          <Link to="/">
            <Button size="large" htmlType="button">
              Back
            </Button>
          </Link>
        </div>
      </Form>
    </>
  );
};
