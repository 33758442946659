import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { BaseFilterData } from "..";

interface TypeCurrencyProps {
  filter: TypeCurrencyFilterData;
}

export const TypeCurrency: (props: TypeCurrencyProps) => JSX.Element | null = ({
  filter,
}) => {
  const [operator, setOperator] = useState<string | null>(null);
  const [value, setValue] = useState<TypeCurrencyValue["value"]>(
    filter.value ? filter.value.value : ""
  );
  const isFilterValid = operator && value;
  useEffect(()=>{
    setOperator(filter.value ? filter.value.operator: "");
    setValue(filter.value ? filter.value.value : "");
  }, [filter.value])
  return (
    <div className="flex flex-row flex-nowrap items-center gap-4">
      <Select
        className="w-40"
        options={filter.config.operatorOptions}
        onChange={(v) => {
          setOperator(v);
        }}
        value={operator}
      />
      <Input
        prefix={`$`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      ></Input>
      <Button
        type="primary"
        onClick={() => {
          if (isFilterValid) {
            filter.onChange({
              name: filter.name,
              value: {
                operator: operator,
                value: value,
              },
            });
          }
        }}
        icon={<SearchOutlined />}
        disabled={!isFilterValid}
      >
        Apply
      </Button>
    </div>
  );
};

export interface TypeCurrencyValue {
  operator: string;
  value: string | number;
}
export interface TypeCurrencyConfig {
  operatorOptions: {
    label: string;
    value: string;
  }[];
}
export type TypeCurrencyFilterData = BaseFilterData<
  TypeCurrencyValue,
  TypeCurrencyConfig
>;
