import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { BaseFilterData } from "..";

interface TypeInputProps {
  filter: TypeInputFilterData
}

export const TypeInput: (
  props: TypeInputProps
) => JSX.Element | null = ({
  filter
}) => {
  const { placeholder = 'Search' } = filter.config;
  const [keyword, setKeyword] = useState<TypeInputValue>(filter.value ? filter.value : "");
  useEffect(()=>{
    setKeyword(filter.value ? filter.value : "");
  }, [filter.value])
  return (
    <div className="flex flex-row flex-nowrap items-center gap-4">
      <Input placeholder={ placeholder } value={keyword} defaultValue={filter.value ? filter.value : ""} onChange={(e) => {
        setKeyword(e.target.value)
      }}></Input>
      <Button
        type="primary"
        onClick={() => {
          filter.onChange({
            name: filter.name,
            value: keyword
          })
        }}
        icon={<SearchOutlined />}
        disabled={!keyword}
      >
        Apply
      </Button>
    </div>
  );
};

export type TypeInputValue = string|number;
export interface TypeInputConfig {
  placeholder?:string
}
export type TypeInputFilterData = BaseFilterData<TypeInputValue, TypeInputConfig>;
