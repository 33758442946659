import { UploadOutlined } from "@ant-design/icons";
import {
  CreateOrganisationRequest,
  UpdateOrganisationsRequest,
} from "@progresspay-next/dtos";
import { constFees, organisationHelper } from "@progresspay-next/shared";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Badge,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../../components/layouts/InternalLayout";
import { getApi } from "../../utils/api";
import { baseURL, getHeaders } from "../../utils/api/_request";
import {
  useQueryOrganisationById,
  useQueryProjectsNonAdmin,
} from "../../utils/query";
import { OrganisationFormIntegrationItems } from "../OrganisationProfile";
import { OrganisationsFormChildrenTab } from "./OrganisationsFormChildrenTab";
import { OrganisationsFormDiscountsTab } from "./OrganisationsFormDiscountsTab";
import { OrganisationsFormIntegrationTab } from "./OrganisationsFormIntegrationTab";
import { OrganisationBasicSettingsItems, OrganisationsFormSettingsTab } from "./OrganisationsFormSettingsTab";
import { OrganisationsFormUsersTab } from "./OrganisationsFormUsersTab";
import { PageHeader } from "@/components/PageHeader";

interface OrganisationsFormProps {
  id?: string | null;
  parentId?: string | null;
  context?: "drawer" | "page";
  onActionSuccess?: () => void;
}

export const OrganisationsForm: (
  props: OrganisationsFormProps
) => JSX.Element | null = ({
  id,
  context = "page",
  parentId = null,
  onActionSuccess,
}) => {
  const { message } = App.useApp();
  const urlParams = useParams();
  const api = getApi();
  const navigate = useNavigate();
  const [form] = useForm();
  const queryClient = useQueryClient();
  if (context == "page") {
    id = urlParams.id;
  }
  const isNew = !id;

  const createOrganisation = useMutation({
    mutationFn: (payload: CreateOrganisationRequest) => {
      return api.createOrganisation(payload);
    },
    onSuccess: (data, variables, context) => {
      message.success("Your organisation has been created successfully.");
      navigate("/admin/organisations");
      onActionSuccess && onActionSuccess();
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
    onError: () => {
      message.error("There was an error when creating the organisation.");
    },
  });
  const updateOrganisation = useMutation({
    mutationFn: (payload: UpdateOrganisationsRequest) => {
      return api.updateOrganisation(payload);
    },
    onSuccess: (data, variables, context) => {
      message.success("This organisation has been updated successfully.");
      navigate("/admin/organisations");
      onActionSuccess && onActionSuccess();
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
  });

  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const targetOrganisation = useQueryOrganisationById(id as string | number, {
    enabled: !isNew,
  });
  useEffect(() => {
    if (id && isFirstRequest && targetOrganisation.data) {
      const data = targetOrganisation.data;
      for (let k in data) {
        if (k == "address" && data[k]) {
          form.setFieldsValue({
            street: data[k]!.street,
            postcode: data[k]!.postcode,
            city: data[k]!.city,
            state: data[k]!.state,
          });
        } else {
          form.setFieldValue(k, data[k as keyof typeof data]);
        }
      }
      setLogoId(data.logo_attachment_id);

      // If integration value is inherited, set it from parent
      const { isValueInherited, value } =
        organisationHelper.getIntegrationField(data);
      if (isValueInherited) {
        form.setFieldValue("integrations", value);
      }
      setIsFirstRequest(false);
    }
  }, [targetOrganisation.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const targetOrganisationProjectsQuery = useQueryProjectsNonAdmin({
    organisation_id: id,
  });

const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  const pageTitleText = `${isNew ? `Create` : `Edit`} Organisation${targetOrganisation.isSuccess ? ` - ${targetOrganisation.data.name}` : ``}`;
  useEffect(() => {
    if (context == "page") {
      setPageTitleSlotProps && setPageTitleSlotProps({
        type: PageTitleSlotType.SimplePageTitle,
        props: {
          title: pageTitleText,
        },
      });
    }
  }, [context, pageTitleText, setPageTitleSlotProps]);

  const routes = [
    {
      path: "/home",
      breadcrumbName: "Home",
    },
    {
      path: "/admin/organisations",
      breadcrumbName: "Organisations",
    },
  ];
  if (isNew) {
    routes.push({
      path: "/admin/organisations/new",
      breadcrumbName: "Create Organisations",
    });
  } else {
    routes.push({
      path: `/admin/organisations/edit/${id}`,
      breadcrumbName: "Edit Organisation",
    });
  }

  const initialValues = {
    id,
    name: "",
    abn: "",
    acn: "",
    type: "GC",
    street: "",
    postcode: "",
    city: "",
    state: "",
    logo_attachment_id: null,
    metadata: null,
    integrations: {
      pas: {
        type: null,
        iframe: null,
      },
      erp: {
        type: null,
        url: null,
        username: null,
        password: null,
        environment: null,
        application: null,
        version: null,
        key: null,
      },
    },
    parent_id: parentId,
    settings: {
      pp_fee: constFees.defaultProgressPayFeePercent,
      facility_limit: 0,
    }
  };

  const parentOrganisationOnCreate = useQueryOrganisationById(parentId!, {
    enabled: !!parentId && isNew,
  });
  useEffect(() => {
    if (parentOrganisationOnCreate.data) {
      form.setFieldValue(
        "integrations",
        parentOrganisationOnCreate.data.integrations
      );
    }
  }, [parentOrganisationOnCreate.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values: any) => {
    let dto = Object.assign({}, values);
    // Address
    const { street, postcode, city, state } = values;
    if (street || postcode || city || state) {
      dto = Object.assign(dto, {
        address: {
          street,
          postcode: Number(postcode),
          city,
          state,
        },
      });
    }
    if (dto.integrations?.pas?.type === null) {
      delete dto.integrations.pas;
    }
    if (dto.integrations?.erp?.type === null) {
      delete dto.integrations.erp;
    }
    // If integrations fields have not been touched, then nothing should be set.
    if (!!parentId && isNew && !isIntegrationTouched) {
      dto.integrations = null;
    }
    if (isNew) {
      dto = {
        ...dto,
        ...{ source: `PROGRESSPAY` },
      };
      createOrganisation.mutate(dto);
    } else {
      updateOrganisation.mutate(dto);
    }
  };
  const [logoId, setLogoId] = useState<string | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [isIntegrationTouched, setIsIntegrationTouched] = useState(false);

  const signedUrlQuery = useQuery({
    queryKey: ["attachmentUrl", { logoId }],
    queryFn: () => api.getAttachmentUrlById(logoId as string),
    enabled: !!logoId,
  });
  useEffect(() => {
    if (signedUrlQuery.data) {
      setLogoUrl(signedUrlQuery.data.url);
    }
  }, [signedUrlQuery.data]);
  const onLogoChange = ({ file, fileList, event }: any) => {
    if (file.status == "done") {
      setLogoId(file.response.id);
      form.setFieldValue("logo_attachment_id", file.response.id);
    }
  };

  const mainFormContent =
    isNew || targetOrganisation.isSuccess ? (
      <Form
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={handleSubmit}
        autoComplete="off"
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.integrations) {
            setIsIntegrationTouched(true);
          }
        }}
      >
        {isNew ? null : (
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>
        )}

        <Form.Item name="parent_id" noStyle>
          <Input type="hidden"></Input>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Company Logo">
              <Form.Item noStyle name="logo_attachment_id">
                <Input type="hidden" />
              </Form.Item>
              {logoUrl ? <img className="mb-4" src={logoUrl} /> : null}
              <Upload
                showUploadList={false}
                action={`${baseURL}/attachments/organisation-logo`}
                headers={getHeaders()}
                onChange={onLogoChange}
              >
                <Button icon={<UploadOutlined />}>
                  Click to Upload a New Logo
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please input your organisation name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="flex flex-row gap-4">
          <div className="flex-auto">
            <Form.Item
              label="ABN"
              name="abn"
              rules={[
                {
                  required: true,
                  message: "Please input your organisation ABN!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="flex-auto">
            <Form.Item label="ACN" name="acn">
              <Input />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please input your organisation Type!",
            },
          ]}
        >
          <Select disabled>
            <Select.Option value="GC">GC</Select.Option>
            <Select.Option value="SC">SC</Select.Option>
          </Select>
        </Form.Item>

        <div className="flex flex-row gap-4">
          <div className="flex-auto">
            <Form.Item label="Street" name="street">
              <Input />
            </Form.Item>
          </div>
          <div className="flex-auto">
            <Form.Item label="Postcode" name="postcode">
              <Input type="number" />
            </Form.Item>
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex-auto">
            <Form.Item label="City / Suburb" name="city">
              <Input />
            </Form.Item>
          </div>
          <div className="flex-auto">
            <Form.Item label="State" name="state">
              <Input />
            </Form.Item>
          </div>
        </div>

        {isNew? (<OrganisationBasicSettingsItems />): null}

        {isNew ? (
          <OrganisationFormIntegrationItems
            isShowingEarlyPaymentSettingOnly={!!parentId}
            isValueInherited={!!parentId}
          />
        ) : null}

        <Form.Item className="mt-4">
          <Button shape="round" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    ) : (
      <Spin />
    );

  const getTabLabel = (text: string, badgeCount: number | null = null) => {
    const base = <span style={{ padding: `0 12px` }}>{text}</span>;
    if (badgeCount)
      return (
        <Badge color={"rgb(100, 100, 100)"} count={badgeCount} size="small">
          {base}
        </Badge>
      );
    return base;
  };

  const tabItems = [
    {
      label: getTabLabel("Basic"),
      key: "basic",
      children: mainFormContent,
    },
  ];

  const [totalContracts, setTotalContracts] = useState<number | null>(null);
  if (!isNew) {
    const totalUsers =
      targetOrganisation.isSuccess &&
      targetOrganisation.data &&
      targetOrganisation.data.users
        ? targetOrganisation.data.users.length
        : 0;
    const totalChildOrganisations =
      targetOrganisation.isSuccess &&
      targetOrganisation.data &&
      targetOrganisation.data.children
        ? targetOrganisation.data.children.length
        : 0;
    const totalProjects =
      targetOrganisationProjectsQuery.isSuccess &&
      targetOrganisationProjectsQuery.data
        ? targetOrganisationProjectsQuery.data.length
        : 0;

    const isGeneralContractor =
      targetOrganisation.isSuccess && targetOrganisation.data?.type == "GC";

    const isRootOrganisation =
      targetOrganisation.isSuccess && !targetOrganisation.data.parent;

    if (isGeneralContractor && isRootOrganisation) {
      tabItems.push({
        label: getTabLabel("Discounts"),
        key: "discounts",
        children: <OrganisationsFormDiscountsTab id={id} />,
      });
      tabItems.push({
        label: getTabLabel("Settings"),
        key: "settings",
        children: <OrganisationsFormSettingsTab id={id as number | string} />,
      });
    }
    tabItems.push({
      label: getTabLabel("Integration"),
      key: "integration",
      children: <OrganisationsFormIntegrationTab id={id} />,
    });

    if (isRootOrganisation) {
      tabItems.push({
        label: getTabLabel("Users", totalUsers),
        key: "users",
        children: <OrganisationsFormUsersTab id={id as string | number} />,
      });
    }

    if (isGeneralContractor && isRootOrganisation) {
      tabItems.push({
        label: getTabLabel("Child Organisations", totalChildOrganisations),
        key: "childOrganisations",
        children: <OrganisationsFormChildrenTab id={id as string | number} />,
      });
    }
  }

  return (
    <div>
      {context == "page" ? (
        <div>
          <PageHeader routes={routes}></PageHeader>
        </div>
      ) : null}

      {tabItems.length > 1 ? (
        <div className="card-container">
          <Tabs type="card" items={tabItems} />
        </div>
      ) : (
        tabItems[0].children
      )}
    </div>
  );
};
