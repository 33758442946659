import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getApi } from "@/utils/api";
import { queryKey } from "@/utils/query";
import { cn } from "@/utils/ui";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CheckCircle, CircleX, Loader, Loader2, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
export const CheopsIntegrationAutomationModal = ({
  open,
  onOpenChange,
  invoice,
  hasPrerequisite,
  isPrerequisiteSuccess,
}: {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  invoice: InvoiceResponse;
  hasPrerequisite: boolean;
  isPrerequisiteSuccess: boolean | undefined; // Undefined means it's still in progress
}) => {
  const [step, setStep] = useState<number>(hasPrerequisite ? -1 : 0);
  const [errorStep, setErrorStep] = useState<number>();

  const steps = (invoice.contract?.organisation?.integrations?.erp?.steps || [])
    .sort((a, b) => a.step - b.step)
    .map((s) => ({ labels: s.labels, step: s.step }));

  const queryClient = useQueryClient();
  const api = getApi();

  const updatePaymentDate = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.updatePaymentDateCheops(invoice?.id);
    },
    onSuccess: (data) => {
      setStep(1);
    },
    onError: () => {
      setErrorStep(0);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const {
    mutate: updatePaymentDateMutate,
    isPending: updatePaymentDateIsPending,
  } = updatePaymentDate;

  useEffect(() => {
    const isStepExists = (step: number) => {
      return steps.find((s) => s.step === step + 1);
    };
    if (open && errorStep === undefined) {
      if (step == -1) {
        if (isPrerequisiteSuccess === true) {
          setStep(0);
        }
      } else if (step == 0) {
        if (isStepExists(step)) {
          if (!updatePaymentDateIsPending) {
            updatePaymentDateMutate({ id: invoice.id });
          }
        } else {
          setStep(1);
        }
      }
    }
  }, [
    step,
    steps,
    open,
    invoice.id,
    updatePaymentDateMutate,
    updatePaymentDateIsPending,
    isPrerequisiteSuccess,
    errorStep,
  ]);

  const reset = () => {
    // reset
    setStep(hasPrerequisite ? -1 : 0);
    setErrorStep(undefined);
  };

  const onOpenChangeInternal = (v: boolean) => {
    if (v) {
      reset();
    } else {
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    }
    onOpenChange(v);
  };

  const isAllDoneSuccessfully =
    step === steps.length && errorStep === undefined;
  const isError =
    errorStep !== undefined ||
    (hasPrerequisite && isPrerequisiteSuccess === false);
  const isInProcess = !isAllDoneSuccessfully && !isError;

  return (
    <Dialog open={open} onOpenChange={onOpenChangeInternal}>
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        noClose={true}
      >
        <DialogHeader>
          <DialogTitle className="text-xl">
            {hasPrerequisite
              ? `Processing Early Payment Request`
              : `CHEOPS Integration in Progress`}
          </DialogTitle>
        </DialogHeader>
        <div>
          {hasPrerequisite && (
            <div className="flex flex-row flex-nowrap py-2 gap-2 items-center">
              <span className="min-w-[24px]">
                {isPrerequisiteSuccess === false && (
                  <XCircle className="text-red-400"></XCircle>
                )}
                {isPrerequisiteSuccess === true && (
                  <CheckCircle className="text-green-400"></CheckCircle>
                )}
                {isPrerequisiteSuccess === undefined && (
                  <Loader2 className="animate-spin"></Loader2>
                )}
              </span>
              <span>Approve the Early Payment Request</span>
            </div>
          )}
          {steps.map((s) => {
            const i = s.step - 1;
            return s.labels.map((label) => (
              <div
                key={label}
                className="flex flex-row flex-nowrap py-2 gap-2 items-center"
              >
                <span className="min-w-[24px]">
                  {step == i ? (
                    errorStep === i ? (
                      <XCircle className="text-red-400"></XCircle>
                    ) : (
                      <Loader2 className="animate-spin"></Loader2>
                    )
                  ) : null}
                  {step > i ? (
                    <CheckCircle className="text-green-400"></CheckCircle>
                  ) : null}
                </span>
                <span className={cn(step < i && `text-gray-200`)}>{label}</span>
              </div>
            ));
          })}
          ;
        </div>
        <DialogFooter>
          <Button
            onClick={() => onOpenChangeInternal(false)}
            disabled={isInProcess}
          >
            {isError && <CircleX className="h-4 w-4"></CircleX>}
            {isAllDoneSuccessfully && (
              <CheckCircle className="h-4 w-4"></CheckCircle>
            )}
            {isInProcess && <Loader className="animate-spin h-4 w-4"></Loader>}
            <span className="ml-1">Close</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
