import { OrganisationType } from "@progresspay-next/dtos";
import { Button, Col, Drawer, Modal, Row, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import {
  useQueryOrganisationById
} from "../../utils/query";
import { useColumnSearchProps } from "../../utils/tables";
import { OrganisationsForm } from "./OrganisationsForm";

interface OrganisationsFormChildrenTabProps {
  id: number | string;
}

const rowKey = (record: { id: any; erp_id: any }) =>
  `${record.id} - ${record.erp_id}`;

const ExternalResourcesModal = ({
  title = "",
  isModalOpen,
  modalContent,
  afterClose,
}: {
  title?: string;
  isModalOpen: boolean;
  modalContent: JSX.Element | null;
  afterClose: () => void;
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      footer={null}
      onCancel={afterClose}
      width={1000}
    >
      {modalContent}
    </Modal>
  );
};

export const OrganisationsFormChildrenTab: (
  props: OrganisationsFormChildrenTabProps
) => JSX.Element | null = ({ id }) => {
  const [isNewOrgFormOpen, setIsNewOrgFormOpen] = useState(false);

  const targetOrganisation = useQueryOrganisationById(id);
  const internalChildOrgs = targetOrganisation.data?.children || [];

  const columns: ColumnsType<OrganisationType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: "ERP ID",
      dataIndex: "erp_id",
      key: "erp_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<OrganisationType>("name"),
    },
    {
      title: "ABN",
      dataIndex: "abn",
      key: "abn",
    },
    {
      title: "ACN",
      dataIndex: "acn",
      key: "acn",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (_, { type }) =>
        type ? (
          <Tag color={type == "SC" ? "geekblue" : "green"}>{type}</Tag>
        ) : null,
      filters: [
        {
          text: "SC",
          value: "SC",
        },
        {
          text: "GC",
          value: "GC",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value as string) === 0,
    },
  ];


  const orgTables = (data: OrganisationType[], isLoading: boolean) => {
    return (
      <Table
        size="small"
        columns={columns}
        loading={isLoading}
        rowKey={rowKey}
        rowClassName={(record) => (record.id ? "internalRow" : "externalRow")}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <Row justify="space-between">
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                setIsNewOrgFormOpen(true);
              }}
              loading={targetOrganisation.isLoading}
            >
              Create
            </Button>
          </Space>
        </Col>
      </Row>
      <hr />
      {orgTables(
        internalChildOrgs,
        targetOrganisation.isLoading
      )}

      {targetOrganisation.isSuccess ? (
        <Drawer
          title={`Create organisation under ${targetOrganisation.data.name}`}
          placement="right"
          open={isNewOrgFormOpen}
          onClose={()=>{setIsNewOrgFormOpen(false)}}
          maskClosable={false}
          width={"75vw"}
        >
          {isNewOrgFormOpen ? (
            <OrganisationsForm
              context="drawer"
              parentId={targetOrganisation.data.id}
              onActionSuccess={() => {
                setIsNewOrgFormOpen(false);
              }}
            />
          ) : null}
        </Drawer>
      ) : null}
    </div>
  );
};
