import { cn } from "@/utils/ui";
import {
    IntegrationType,
    InvoiceResponse,
    InvoiceStatusTypes
} from "@progresspay-next/dtos";
import {
    activityHelper,
    formatDate,
    momentjsFormat,
} from "@progresspay-next/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Button, Col, List, Row, Statistic } from "antd";
import moment from "moment";
import { getApi } from "../../utils/api";
import { queryKey, useQueryInvoiceActivities } from "../../utils/query";
import { Section } from "../Section";
import { Skeleton } from "../ui/skeleton";

export const InvoiceIntegration = ({
  invoice,
}: {
  invoice: InvoiceResponse;
}) => {
  const { message } = App.useApp();
  const { data: activities } = useQueryInvoiceActivities(invoice.id as string);
  const api = getApi();
  const queryClient = useQueryClient();

  const invalidateAllCaches = () => {
    if (invoice.id) {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceById(invoice.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceByIdNonAdmin(invoice.id),
      });
    }
    queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    queryClient.invalidateQueries({ queryKey: queryKey.invoicesNonAdmin() });
  };

  const updatePaymentDate = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.updatePaymentDate(invoice?.id);
    },
    onSuccess: (data) => {
      message.success("Successfully updated payment date");
      invalidateAllCaches();
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const updatePaymentDateCheops = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.updatePaymentDateCheops(invoice?.id);
    },
    onSuccess: (data) => {
      message.success("Successfully updated payment date");
      invalidateAllCaches();
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const createAPTransactionC = useMutation({
    mutationFn: (payload: any) => {
      return api.createAPTransaction(payload);
    },
    onSuccess: (data) => {
      message.success("Successfully created discount record");
      invalidateAllCaches();
      if (invoice.id)
        queryClient.invalidateQueries({
          queryKey: queryKey.invoiceByIdNonAdmin(invoice.id),
        });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const createAPTransactionI = useMutation({
    mutationFn: (payload: any) => {
      return api.createAPTransaction(payload);
    },
    onSuccess: (data) => {
      message.success("Successfully created ProgressPay fee invoice");
      invalidateAllCaches();
      if (invoice.id)
        queryClient.invalidateQueries({
          queryKey: queryKey.invoiceByIdNonAdmin(invoice.id),
        });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const createNewPMWorksheet = useMutation({
    mutationFn: (payload: any) => {
      return api.createNewPMWorksheet(payload.id);
    },
    onSuccess: (data) => {
      message.success("Successfully created new PMW");
      invalidateAllCaches();
      if (invoice.id)
        queryClient.invalidateQueries({
          queryKey: queryKey.invoiceByIdNonAdmin(invoice.id),
        });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.invoiceActivities(invoice.id),
      });
    },
  });

  const activityList = (verbSuccess: any, verbFail: any) => (
    <ol className="pt-2 pb-2 pl-2 text-xs">
      {activities?.map((activity: any) =>
        [verbSuccess, verbFail].includes(activity.verb) ? (
          <li
            className={cn(
              activityHelper.isOperationSuccess([activity], verbSuccess) &&
                "text-gray-600",
              activityHelper.isOperationPending([activity], verbSuccess) &&
                "text-yellow-600",
              !activityHelper.isOperationSuccess([activity], verbSuccess) &&
                !activityHelper.isOperationPending([activity], verbSuccess) &&
                "text-red-600"
            )}
            key={activity.created_at}
          >
            &#x2022;{" "}
            {moment(activity.created_at).format(momentjsFormat.dateTime)} by{" "}
            {activity.user.first_last}
            {activityHelper.isOperationPending([activity], verbSuccess)
              ? " (incomplete request)"
              : ""}
            {activityHelper.isOperationSuccess([activity], verbSuccess) &&
            activity.verb.indexOf("_FAILED") !== -1
              ? " (record already exists)"
              : ""}
          </li>
        ) : null
      )}
    </ol>
  );

  // Steps is coming from the organisation setup
  const erpSteps =
    invoice.contract?.organisation?.integrations?.erp?.steps || [];
  const erpType = invoice.contract?.organisation?.erp_system;
  // Add extra bits that the frontend needs to render the UI
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }
  const erpStepsWithExtraInfo = activities
    ? erpSteps
        .map((step) => {
          if (erpType === IntegrationType.JOBPAC) {
            if (step.step === 1) {
              return {
                title: step.labels.join(`, `),
                description: (
                  <div style={{ paddingLeft: 20 }}>
                    {activityHelper.isOperationSuccess(
                      activities,
                      `JOBPAC_UPDATE_PAYMENT_DATE`
                    ) ? (
                      <p>
                        Payment date updated in{" "}
                        {invoice.contract?.organisation?.erp_system} from{" "}
                        <b>{formatDate(invoice.original_payment_due_date!)}</b>{" "}
                        to{" "}
                        <b>{formatDate(invoice.revised_payment_due_date!)}</b>
                      </p>
                    ) : null}
                    {activityList(
                      "JOBPAC_UPDATE_PAYMENT_DATE",
                      "JOBPAC_UPDATE_PAYMENT_DATE_FAILED"
                    )}
                  </div>
                ),
                action: () => (
                  <Button
                    onClick={() => updatePaymentDate.mutate({ id: invoice.id })}
                    disabled={
                      invoice.invoice_status !== InvoiceStatusTypes.APPROVED
                    }
                    loading={updatePaymentDate.isPending}
                  >
                    {activityHelper.isOperationSuccess(
                      activities,
                      "JOBPAC_UPDATE_PAYMENT_DATE"
                    ) ||
                    activityHelper.isOperationPending(
                      activities,
                      "JOBPAC_UPDATE_PAYMENT_DATE"
                    )
                      ? "Re-run"
                      : "Run"}
                  </Button>
                ),
              };
            } else if (step.step === 2) {
              return {
                title: step.labels.join(`, `),
                description: (
                  <div style={{ paddingLeft: 20 }}>
                    {activityHelper.isOperationSuccess(
                      activities,
                      `JOBPAC_ADD_CREDIT_NOTE_CREDITOR`
                    ) ? (
                      <p>
                        Credit note added for{" "}
                        <b>{invoice.contract?.organisation?.erp_id}</b>
                      </p>
                    ) : null}
                    {activityList(
                      "JOBPAC_ADD_CREDIT_NOTE_CREDITOR",
                      "JOBPAC_ADD_CREDIT_NOTE_CREDITOR_FAILED"
                    )}
                  </div>
                ),
                action: () => (
                  <Button
                    onClick={() =>
                      createAPTransactionC.mutate({
                        id: invoice.id,
                        invoiceOrCreditFlag: "C",
                      })
                    }
                    loading={createAPTransactionC.isPending}
                    disabled={
                      invoice.invoice_status !== InvoiceStatusTypes.APPROVED
                    }
                  >
                    {activityHelper.isOperationSuccess(
                      activities,
                      "JOBPAC_ADD_CREDIT_NOTE_CREDITOR"
                    ) ||
                    activityHelper.isOperationPending(
                      activities,
                      "JOBPAC_ADD_CREDIT_NOTE_CREDITOR"
                    )
                      ? "Re-run"
                      : "Run"}
                  </Button>
                ),
              };
            } else if (step.step === 3) {
              return {
                title: step.labels.join(`, `),
                description: (
                  <div style={{ paddingLeft: 20 }}>
                    {activityHelper.isOperationSuccess(
                      activities,
                      `JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY`
                    ) ? (
                      <p>
                        Credit note added for <b>ProgressPay</b>
                      </p>
                    ) : null}
                    {activityList(
                      "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY",
                      "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY_FAILED"
                    )}
                  </div>
                ),
                action: () => (
                  <Button
                    onClick={() =>
                      createAPTransactionI.mutate({
                        id: invoice.id,
                        invoiceOrCreditFlag: "I",
                      })
                    }
                    loading={createAPTransactionI.isPending}
                    disabled={
                      invoice.invoice_status !== InvoiceStatusTypes.APPROVED
                    }
                  >
                    {activityHelper.isOperationSuccess(
                      activities,
                      "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY"
                    ) ||
                    activityHelper.isOperationPending(
                      activities,
                      "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY"
                    )
                      ? "Re-run"
                      : "Run"}
                  </Button>
                ),
              };
            } else if (step.step === 4) {
              return {
                title: step.labels.join(`, `),
                description: (
                  <div style={{ paddingLeft: 20 }}>
                    {activityHelper.isOperationSuccess(
                      activities,
                      `JOBPAC_ADD_NEW_PM_WORKSHEET`
                    ) ? (
                      <p>Project Manager worksheet added</p>
                    ) : null}
                    {activityList(
                      "JOBPAC_ADD_NEW_PM_WORKSHEET",
                      "JOBPAC_ADD_NEW_PM_WORKSHEET_FAILED"
                    )}
                  </div>
                ),
                action: () => (
                  <Button
                    onClick={() =>
                      createNewPMWorksheet.mutate({ id: invoice.id })
                    }
                    loading={createNewPMWorksheet.isPending}
                    disabled={
                      invoice.invoice_status !== InvoiceStatusTypes.APPROVED
                    }
                  >
                    {activityHelper.isOperationSuccess(
                      activities,
                      "JOBPAC_ADD_NEW_PM_WORKSHEET"
                    ) ||
                    activityHelper.isOperationPending(
                      activities,
                      "JOBPAC_ADD_NEW_PM_WORKSHEET"
                    )
                      ? "Re-run"
                      : "Run"}
                  </Button>
                ),
              };
            }
          } else if (erpType === IntegrationType.CHEOPS) {
            if (step.step === 1) {
              return {
                title: step.labels.join(`, `),
                description: (
                  <div style={{ paddingLeft: 20 }}>
                    {activityHelper.isOperationSuccess(
                      activities,
                      `CHEOPS_UPDATE_PAYMENT_DATE`
                    ) ? (
                      <p>
                        Payment date updated in{" "}
                        {invoice.contract?.organisation?.erp_system} from{" "}
                        <b>{formatDate(invoice.original_payment_due_date!)}</b>{" "}
                        to{" "}
                        <b>{formatDate(invoice.revised_payment_due_date!)}</b>
                      </p>
                    ) : null}
                    {activityList(
                      "CHEOPS_UPDATE_PAYMENT_DATE",
                      "CHEOPS_UPDATE_PAYMENT_DATE_FAILED"
                    )}
                  </div>
                ),
                action: () => (
                  <Button
                    onClick={() =>
                      updatePaymentDateCheops.mutate({ id: invoice.id })
                    }
                    disabled={
                      invoice.invoice_status !== InvoiceStatusTypes.APPROVED
                    }
                    loading={updatePaymentDateCheops.isPending}
                  >
                    {activityHelper.isOperationSuccess(
                      activities,
                      "CHEOPS_UPDATE_PAYMENT_DATE"
                    ) ||
                    activityHelper.isOperationPending(
                      activities,
                      "CHEOPS_UPDATE_PAYMENT_DATE"
                    )
                      ? "Re-run"
                      : "Run"}
                  </Button>
                ),
              };
            }
          } else if (erpType === IntegrationType.VISTA) {
            if (step.step === 1) {
              return null;
            }
          }
          console.error("Automation step not supported", { step });
          return null;
        })
        .filter(notEmpty)
    : undefined;

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Section>
            <Statistic
              title="Payment Application System"
              value={
                invoice.contract?.organisation?.pas_system || "Not connected"
              }
              valueStyle={{ fontSize: 18 }}
            />
            <br />
            <Statistic
              title="Ref"
              value={`${invoice?.external_data?.pas_ref || ""}`}
              formatter={(v) => v}
              valueStyle={{ fontSize: 18 }}
            />
          </Section>
        </Col>
        <Col span={12}>
          <Section>
            <Statistic
              title="ERP Integration"
              value={
                invoice.contract?.organisation?.erp_system || "Not connected"
              }
              valueStyle={{ fontSize: 18 }}
            />
            <br />
            <Statistic
              title="Transaction ID"
              value={`${invoice.erp_id || ""}`}
              formatter={(v) => v}
              valueStyle={{ fontSize: 18 }}
            />
          </Section>
        </Col>
      </Row>

      <br />

      {invoice.contract?.erp_id ? (
        <Row gutter={16}>
          <Col span={24}>
            <div
              style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 20 }}
            >
              {invoice.contract?.organisation?.erp_system} Automation
            </div>
            <Section>
              {erpStepsWithExtraInfo === undefined ? <List
                  itemLayout="horizontal"
                  dataSource={erpSteps}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.labels.join(`, `)}
                        description={<Skeleton className="w-full rounded h-10"/>}
                      />
                    </List.Item>
                  )}
                /> : (
                <List
                  itemLayout="horizontal"
                  dataSource={erpStepsWithExtraInfo}
                  renderItem={(item, index) => (
                    <List.Item actions={[item.action()]}>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              )}
            </Section>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
