import { EarlyPaymentRequestSummary } from "@/components/invoices/EarlyPaymentRequestSummary";
import { InvoiceAuditTrail } from "@/components/invoices/InvoiceAuditTrail";
import { InvoiceIntegration } from "@/components/invoices/InvoiceIntegration";
import {
    InvoiceAction,
    RequestEarlyPaymentWidget,
} from "@/components/invoices/RequestEarlyPaymentWidget";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
    DiscountType,
    IntegrationType,
    InvoiceResponse,
} from "@progresspay-next/dtos";
import {
    momentjsFormat,
    organisationHelper
} from "@progresspay-next/shared";
import { Tabs } from "antd";
import currency from "currency.js";
import { AlertCircle } from "lucide-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { useContextStore } from "../../stores/context";
import { DocumentsSection } from "./DocumentsSection";
import { DrawerContext } from "./EarlyPaymentRequestDrawerWithButton";
import { InvoiceAdminControlPanel } from "./InvoiceAdminControlPanel";

export const InvoiceDetails = ({ invoice }: { invoice: InvoiceResponse }) => {
  const contextStore = useContextStore();
  const isAdmin = contextStore.me?.is_system_admin;
  const isGC = contextStore.me?.is_gc;
  const { setFooterNode } = useContext(DrawerContext);

  invariant(invoice.contract?.organisation, "Invoice organisation missing");
  invariant(
    invoice.contract?.sc_organisation,
    "Invoice sc organisation missing"
  );
  const [discount, setDiscount] = useState<DiscountType | null>(
    invoice.discount
  );

  // Addendum, RCTI, Approved, Submitted
  const attachments = [];
  if (invoice.addendum_attachment_id) {
    attachments.push({
      attachmentId: invoice.addendum_attachment_id,
      name: "Early Payment Addendum",
    });
  }
  if (invoice.external_data?.files?.claim?.rcti?.url) {
    attachments.push({
      url: invoice.external_data.files.claim.rcti.url,
      name: `RCTI`,
    });
  }
  if (invoice.external_data?.files?.claim?.approved_claim?.url) {
    // When a claim is approved then unapproved in Payapps, the approved_claim document is still there
    // In this particular case, don't show the approved claim
    if (
      invoice.source === IntegrationType.PAYAPPS &&
      invoice.external_data.claim_status === "SUBMITTED"
    ) {
      // Do nothing
    } else {
      attachments.push({
        url: invoice.external_data.files.claim.approved_claim.url,
        name: `Approved Claim`,
      });
    }
  }
  if (invoice.external_data?.files?.claim?.submitted_claim?.url) {
    attachments.push({
      url: invoice.external_data.files.claim.submitted_claim.url,
      name: `Submitted Claim`,
    });
  }

  const {
    original_payment_due_date,
    revised_payment_due_date,
    external_data: { net_claim_incl_tax, claim_number },
  } = invoice;

  const tabItems = [
    {
      key: "1",
      label: "Invoice Details",
      children: (
        <div className="-mt-4 rounded-b-lg border-b border-l border-r bg-white p-6 text-base">
          <div className="mb-6 flex flex-row gap-6">
            <table className="w-full text-sm">
              <tbody>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Customer Name</td>
                  <td className="py-2">
                    {invoice.contract?.sc_organisation.name}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Customer ABN</td>
                  <td className="py-2">
                    {invoice.contract?.sc_organisation.abn}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Project</td>
                  <td className="py-2">{invoice.contract?.project.name}</td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Contract</td>
                  <td className="py-2">{invoice.contract?.name}</td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Invoice Number</td>
                  <td className="py-2">{claim_number}</td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">
                    Amount (incl. GST)
                  </td>
                  <td className="py-2">
                    {currency(net_claim_incl_tax).format()}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pr-2 text-gray-500">Original Due Date</td>
                  <td className="py-2">
                    {original_payment_due_date ? moment(original_payment_due_date, "YYYY-MM-DD").format(momentjsFormat.dateText) : 'Standard Terms'}
                  </td>
                </tr>
                {revised_payment_due_date ? (
                  <tr>
                    <td className="py-2 pr-2 text-gray-500">
                      Revised Due Date
                    </td>
                    <td className="py-2">
                      {moment(revised_payment_due_date, "YYYY-MM-DD").format(
                        momentjsFormat.dateText
                      )}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Activity Log",
      children: (
        <div className="-mt-4 rounded-b-lg border-b border-l border-r bg-white p-4">
          <InvoiceAuditTrail invoice={invoice} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (isGC) {
      setFooterNode(
        <InvoiceAction invoice={invoice} discount={invoice.discount} />
      );
    }
  }, [isGC, invoice, setFooterNode]);

  if (isGC) {
    tabItems.push({
      key: "3",
      label: "Integration",
      children: (
        <div className="-mt-4 rounded-b-lg bg-white p-4">
          <InvoiceIntegration invoice={invoice} />
        </div>
      ),
    });
  }

  if (isAdmin) {
    tabItems.push({
      key: "admin",
      label: "System Admin",
      children: (
        <div className="-mt-4 rounded-b-lg bg-white p-4">
          <InvoiceAdminControlPanel invoice={invoice} />
        </div>
      ),
    });
  }

  const showMissingTransactionWarning =
    isGC &&
    invoice.external_data.claim_status === "APPROVED" &&
    (organisationHelper.isERPJobpac(invoice.contract.organisation) || organisationHelper.isERPCheops(invoice.contract.organisation)) &&
    !invoice.erp_id;

  const showUnpostedClaimWarning =
    isGC &&
    (organisationHelper.isERPJobpac(invoice.contract.organisation) || organisationHelper.isERPCheops(invoice.contract.organisation)) &&
    invoice.external_data.claim_status === "APPROVED" &&
    invoice.invoice_status == "REQUESTED" &&
    !invoice.is_posted_claim;

  const showJobpacCostCentreWarning =
    isGC &&
    organisationHelper.isERPJobpac(invoice.contract.organisation) &&
    invoice.external_data.claim_status === "APPROVED" &&
    invoice.invoice_status == "REQUESTED" &&
    !invoice.valid_cost_centre;

  // We don't show the document section when
  // - there are no attachments
  // - the claim is a draft claim in the Payapps integration (happens after the claim is retracted)
  const isHidingDocumentSection =
    attachments.length == 0 ||
    (invoice.source === IntegrationType.PAYAPPS &&
      invoice.external_data.claim_status === "DRAFT CLAIM");

  return isGC ? (
    // GC View - show in drawer
    <div className="flex flex-row flex-wrap gap-6">
      {showMissingTransactionWarning ? (
        <div className="w-full flex-initial">
          <Alert variant={"warning"}>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Cannot find a transaction ID for this request. Data is likely out
              of sync, so please contact support to have this issue resolved.
            </AlertDescription>
          </Alert>
        </div>
      ) : null}
      {showUnpostedClaimWarning ? (
        <div className="w-full flex-initial">
          <Alert variant={"warning"}>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              This Invoice needs to be posted in {invoice.contract?.organisation?.erp_system} before you can approve
              the discount.
            </AlertDescription>
          </Alert>
        </div>
      ) : null}
      {showJobpacCostCentreWarning ? (
        <div className="w-full flex-initial">
          <Alert variant={"warning"}>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>Invalid Cost Centre settings.</AlertDescription>
          </Alert>
        </div>
      ) : null}
      {/* {invoice.discount ? (
        <div className="w-full flex-initial">
          <FundsSummary invoice={invoice} />
        </div>
      ) : null} */}
      {invoice.discount ? (
        <div className="w-full flex-initial">
          <EarlyPaymentRequestSummary invoice={invoice} />
        </div>
      ) : null}
      <div className="w-full flex-initial">
        <Tabs type="card" items={tabItems} />
      </div>
      <div className="w-full flex-initial">
        { !isHidingDocumentSection && <DocumentsSection attachments={attachments} />}
      </div>
    </div>
  ) : (
    // SC View - in an iframe
    <div className="flex flex-row flex-wrap gap-6">
      <div className="flex-1 flex flex-col gap-6">
        <Tabs type="card" items={tabItems} />
        { !isHidingDocumentSection && <DocumentsSection attachments={attachments} />}
      </div>
      <div className="flex-1 mt-10">
        <RequestEarlyPaymentWidget
          invoice={invoice}
        ></RequestEarlyPaymentWidget>
      </div>
    </div>
  );
};
