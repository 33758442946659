import { IntegrationType, InvoiceResponse } from "@progresspay-next/dtos";
import { CheopsIntegrationAutomationModal as CheopsIntegrationAutomationModalRaw } from "./CheopsIntegrationAutomationModal";
import { JobpacIntegrationAutomationModal as JobpacIntegrationAutomationModalRaw } from "./JobpacIntegrationAutomationModal";
import { memo } from "react";

type IntegrationAutomationModalProps = {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  invoice: InvoiceResponse;
  hasPrerequisite: boolean;
  isPrerequisiteSuccess: boolean | undefined; // Undefined means it's still in progress
};

const isPropsEqual = (
  prevProps: IntegrationAutomationModalProps,
  nextProps: IntegrationAutomationModalProps
) => {
  return (
    prevProps.open === nextProps.open &&
    prevProps.invoice.id === nextProps.invoice.id &&
    prevProps.hasPrerequisite === nextProps.hasPrerequisite &&
    prevProps.isPrerequisiteSuccess === nextProps.isPrerequisiteSuccess
  );
};
const CheopsIntegrationAutomationModal = memo(
  CheopsIntegrationAutomationModalRaw,
  isPropsEqual
);
const JobpacIntegrationAutomationModal = memo(
  JobpacIntegrationAutomationModalRaw,
  isPropsEqual
);

export const IntegrationAutomationModal = ({
  open,
  onOpenChange,
  invoice,
  hasPrerequisite,
  isPrerequisiteSuccess,
}: IntegrationAutomationModalProps) => {
  if (
    invoice.contract?.organisation?.integrations?.erp?.type ===
    IntegrationType.CHEOPS
  ) {
    return (
      <CheopsIntegrationAutomationModal
        open={open}
        onOpenChange={onOpenChange}
        invoice={invoice}
        hasPrerequisite={hasPrerequisite}
        isPrerequisiteSuccess={isPrerequisiteSuccess}
      />
    );
  } else if (
    invoice.contract?.organisation?.integrations?.erp?.type ===
    IntegrationType.JOBPAC
  ) {
    return (
      <JobpacIntegrationAutomationModal
        open={open}
        onOpenChange={onOpenChange}
        invoice={invoice}
        hasPrerequisite={hasPrerequisite}
        isPrerequisiteSuccess={isPrerequisiteSuccess}
      />
    );
  }
  return null;
};
