import { App as AntDApp, ConfigProvider } from "antd";
import React from "react";
import { Router } from "./pages";
// import './styles/less/index.less';
import { QueryClientProvider } from "@tanstack/react-query";
import "./styles/css/tailwind.css";
import { useSetMessageDefault } from "./utils/messageDefault";
import { useGetQueryClientWithDefault } from "./utils/queryClientDefault";

const InnerApp = () => {
  const queryClient = useGetQueryClientWithDefault();
  useSetMessageDefault();
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
      {/* <ReactQueryDevtools initialIsOpen={false} position={"bottom"} /> */}
    </QueryClientProvider>
  );
};

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: `#108EE9`,
          fontFamily: `'DM Sans', sans-serif`,
        },
      }}
    >
      <AntDApp>
        <InnerApp />
      </AntDApp>
    </ConfigProvider>
  );
};

export default App;
