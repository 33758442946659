import { DiscountWidget } from "@/components/invoices/DiscountWidget";
import { useInvoiceActionStore } from "@/stores/invoiceAction";
import { cn } from "@/utils/ui";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
    DiscountsType,
    InvoiceResponse,
    InvoiceStatusTypes,
    UpdateInvoicesRequest,
} from "@progresspay-next/dtos";
import {
    discountCalculator,
    invoiceHelper,
    organisationHelper,
} from "@progresspay-next/shared";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { AlertTriangle, CheckCircleIcon, Loader } from "lucide-react";
import moment from "moment";
import { Fragment, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContextStore } from "../../stores/context";
import { getApi } from "../../utils/api";
import { queryKey } from "../../utils/query";
import { TermsCheckbox } from "../misc/TermsCheckbox";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { ConfirmDialog } from "../ui/confirm";
import { IntegrationAutomationModal } from "./integration-automation-modal/IntegrationAutomationModal";

export const isSCWaitingForGCApprovingDiscount = (
  invoice: InvoiceResponse,
  isGC: boolean
) => invoice.invoice_status == InvoiceStatusTypes.REQUESTED && !isGC;

export const isWidgetEditable = (invoice: InvoiceResponse, isGC: boolean) =>
  !isGC &&
  invoice.invoice_status === InvoiceStatusTypes.REQUESTED &&
  invoice.confirmed_at === null;

export const isWidgetLightStyled = (invoice: InvoiceResponse, isGC: boolean) =>
  (isSCWaitingForGCApprovingDiscount(invoice, isGC) ||
    invoice.invoice_status == InvoiceStatusTypes.CANCELLED ||
    invoice.invoice_status == InvoiceStatusTypes.APPROVED) &&
  !isWidgetEditable(invoice, isGC);

export const InvoiceAction = ({
  invoice,
  discount,
}: {
  discount: {
    discount: number;
    payrun_date: string;
  } | null;
  invoice: InvoiceResponse;
}) => {
  const { message } = App.useApp();
  const api = getApi();
  const queryClient = useQueryClient();
  const contextStore = useContextStore();
  const isGC = contextStore.me?.is_gc;
  const invoiceUserActionStore = useInvoiceActionStore();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isShowingTermsError, setIsShowingTermsError] = useState(false);
  const navigate = useNavigate();

  const checkIfTermsAccepted = () => {
    if (!isAccepted) {
      setIsShowingTermsError(true);
      return false;
    }
    setIsShowingTermsError(false);
    return true;
  };

  const termsTextRawHtml = invoiceHelper.generateTermsTextRawHtml(
    {
      scName: invoice.contract?.sc_organisation?.name || "",
      gcName: invoice.contract?.organisation?.name || "",
    },
    invoice.external_data.claim_status === "APPROVED"
  );

  const gcRejectEarlyPaymentRequest = useMutation({
    mutationFn: (payload: UpdateInvoicesRequest) => {
      return api.rejectEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully updated!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
  });

  const requestEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.requestEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully updated!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error confirming the early payment request."
        );
      }
    },
  });

  const approveEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.approveEarlyPayment(payload);
    },
    onMutate: () => {
      // Integration automation modal
      if (
        invoice.contract?.organisation &&
        (organisationHelper.isERPJobpac(invoice.contract?.organisation) ||
          organisationHelper.isERPCheops(invoice.contract?.organisation))
      ) {
        setIsIntegrationAutomationModalOpen(true);
      }
    },
    onSuccess: (data) => {
      invoiceUserActionStore.setUserActionTargetId(invoice.id);
      if (
        data.contract?.organisation &&
        (organisationHelper.isERPJobpac(data.contract?.organisation) ||
          organisationHelper.isERPCheops(data.contract?.organisation))
      ) {
      } else {
        message.success(
          "Early payment request has been successfully approved!"
        );
        queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
      }
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error approving the early payment request."
        );
      }
    },
  });

  const cancelEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.cancelEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully cancelled!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error cancelling the early payment request."
        );
      }
    },
  });

  const confirmEarlyPaymentRequest = useMutation({
    mutationFn: () => {
      return api.confirmEarlyPayment({
        id: invoice.id,
        discount: discount,
        terms_accepted: isAccepted,
      });
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully confirmed!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error confirming the early payment request."
        );
      }
    },
  });

  const updateEarlyPaymentRequest = useMutation({
    mutationFn: () => {
      return api.updateEarlyPayment({
        id: invoice.id,
        discount: discount,
        terms_accepted: isAccepted,
      });
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully updated!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error("There was an error updating the early payment request.");
      }
    },
  });

  const requestDiscountSC = () => {
    if (discount) {
      if (!checkIfTermsAccepted()) {
        return;
      }
      const payload = {
        id: invoice.id,
        discount: discount,
        terms_accepted: isAccepted,
      };
      requestEarlyPaymentRequest.mutate(payload);
    }
  };

  // Only SUBMITTED and APRROVED in Payapps
  const externalInvoiceStatus = {
    isSubmitted: () => invoice.external_data.claim_status === "SUBMITTED",
    isApproved: () => invoice.external_data.claim_status === "APPROVED",
  };

  const invoiceState = {
    isPendingSCSubmission: () =>
      !requestEarlyPaymentRequest.isSuccess &&
      [
        InvoiceStatusTypes.CREATED,
        InvoiceStatusTypes.INTENDED,
        InvoiceStatusTypes.AVAILABLE,
        InvoiceStatusTypes.OFFERED,
      ].includes(invoice.invoice_status as InvoiceStatusTypes),
    isPendingGCApproval: () =>
      ((requestEarlyPaymentRequest.isSuccess ||
        invoice.invoice_status == InvoiceStatusTypes.REQUESTED) &&
        !approveEarlyPaymentRequest.isSuccess) ||
      isIntegrationAutomationModalOpen,
    isApproved: () =>
      approveEarlyPaymentRequest.isSuccess ||
      invoice.invoice_status == InvoiceStatusTypes.APPROVED,
  };

  const [isCancelRequestConfirmOpen, setIsCancelRequestConfirmOpen] =
    useState<boolean>(false);

  const [isApproveRequestConfirmOpen, setIsApproveRequestConfirmOpen] =
    useState<boolean>(false);

  const [isRejectRequestConfirmOpen, setIsRejectRequestConfirmOpen] =
    useState<boolean>(false);

  const [
    isIntegrationAutomationModalOpen,
    setIsIntegrationAutomationModalOpen,
  ] = useState<boolean>(false);

  let actionNodes: ReactNode[] = [];
  let isTermsNeeded = false;
  const isSCToConfirm = invoice.confirmation_required && !isGC;

  const isDiscountChanged =
    !!discount && discount.payrun_date !== invoice.discount?.payrun_date;

  if (isGC && externalInvoiceStatus.isSubmitted()) {
    if (
      invoice.invoice_status !== InvoiceStatusTypes.ELIGIBLE &&
      invoice.invoice_status !== InvoiceStatusTypes.REJECTED
    ) {
      actionNodes = [
        <>
          <Button
            className="w-full disabled:text-gray-500"
            onClick={() => {
              setIsRejectRequestConfirmOpen(true);
            }}
            variant={"secondary"}
          >
            {gcRejectEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Reject`
            )}
          </Button>
          <ConfirmDialog
            open={isRejectRequestConfirmOpen}
            onOpenChange={(v) => setIsRejectRequestConfirmOpen(v)}
            callback={() => {
              gcRejectEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to reject this early payment request?`}
          ></ConfirmDialog>
        </>,
        <Button className="w-full disabled:text-gray-500" disabled={true}>
          Claim Pending Approval in Payapps
        </Button>,
      ];
    }
  } else if (invoiceState.isPendingSCSubmission()) {
    if (isGC) {
      actionNodes = [
        <>
          <Button
            className="w-full disabled:text-gray-500"
            onClick={() => {
              setIsRejectRequestConfirmOpen(true);
            }}
            variant={"secondary"}
          >
            {gcRejectEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Reject`
            )}
          </Button>
          <ConfirmDialog
            open={isRejectRequestConfirmOpen}
            onOpenChange={(v) => setIsRejectRequestConfirmOpen(v)}
            callback={() => {
              gcRejectEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to reject this early payment request?`}
          ></ConfirmDialog>
        </>,
      ];
    } else {
      actionNodes = [
        <>
          <Button
            className="w-full bg-white"
            onClick={() => {
              setIsCancelRequestConfirmOpen(true);
            }}
            variant={"secondary"}
          >
            {cancelEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Cancel Request`
            )}
          </Button>
          <ConfirmDialog
            open={isCancelRequestConfirmOpen}
            onOpenChange={(v) => setIsCancelRequestConfirmOpen(v)}
            callback={() => {
              cancelEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to cancel your early payment request?`}
          >
            By cancelling you may not be able to resubmit another request if
            early payment is no longer available.
          </ConfirmDialog>
        </>,
        <Button
          className="w-full disabled:text-gray-500"
          disabled={discount === null}
          onClick={() => {
            requestDiscountSC();
          }}
        >
          {requestEarlyPaymentRequest.isPending ? (
            <Loader className="animate-spin ml-2 h-4 w-4" />
          ) : (
            `Request Early Payment`
          )}
        </Button>,
      ];
      isTermsNeeded = true;
    }
  } else if (invoiceState.isPendingGCApproval()) {
    if (isGC && externalInvoiceStatus.isApproved()) {
      actionNodes = [
        <>
          <Button
            className="w-full disabled:text-gray-500"
            onClick={() => {
              setIsRejectRequestConfirmOpen(true);
            }}
            variant={"secondary"}
            disabled={
              approveEarlyPaymentRequest.isPending ||
              gcRejectEarlyPaymentRequest.isPending
            }
          >
            {gcRejectEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Reject`
            )}
          </Button>
          <ConfirmDialog
            open={isRejectRequestConfirmOpen}
            onOpenChange={(v) => setIsRejectRequestConfirmOpen(v)}
            callback={() => {
              gcRejectEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to reject this early payment request?`}
          ></ConfirmDialog>
        </>,
        <>
          <Button
            className="w-full disabled:text-gray-500"
            disabled={discount === null || !invoice.can_approve}
            onClick={() => {
              setIsApproveRequestConfirmOpen(true);
            }}
          >
            {approveEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : invoice.confirmation_required ? (
              `Pending Confirmation`
            ) : (
              `Approve`
            )}
          </Button>
          <ConfirmDialog
            open={isApproveRequestConfirmOpen}
            onOpenChange={(v) => setIsApproveRequestConfirmOpen(v)}
            callback={() => {
              approveEarlyPaymentRequest.mutate({
                id: invoice.id,
                discount: invoice.discount,
              });
            }}
            title={`Are you sure you would like to approve this early payment request?`}
          ></ConfirmDialog>
        </>,
      ];
    } else if (isGC && !invoiceState.isApproved()) {
      actionNodes = [
        <>
          <Button
            className="w-full disabled:text-gray-500"
            onClick={() => {
              setIsRejectRequestConfirmOpen(true);
            }}
            variant={"secondary"}
          >
            {gcRejectEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Reject`
            )}
          </Button>
          <ConfirmDialog
            open={isRejectRequestConfirmOpen}
            onOpenChange={(v) => setIsRejectRequestConfirmOpen(v)}
            callback={() => {
              gcRejectEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to reject this early payment request?`}
          ></ConfirmDialog>
        </>,
      ];
    } else if (!isGC) {
      actionNodes = [
        <>
          <Button
            className="w-full bg-white"
            onClick={() => {
              setIsCancelRequestConfirmOpen(true);
            }}
            variant={"secondary"}
          >
            {cancelEarlyPaymentRequest.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : (
              `Cancel Request`
            )}
          </Button>
          <ConfirmDialog
            open={isCancelRequestConfirmOpen}
            onOpenChange={(v) => setIsCancelRequestConfirmOpen(v)}
            callback={() => {
              cancelEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to cancel your early payment request?`}
          >
            By cancelling you may not be able to resubmit another request if
            early payment is no longer available.
          </ConfirmDialog>
        </>,
      ];
    }
  } else {
    actionNodes = [];
  }

  if (isSCToConfirm) {
    // Confirm button has both the function of update & confirm
    actionNodes.push(
      <Button
        className="w-full disabled:text-gray-500"
        onClick={() => {
          if (!checkIfTermsAccepted()) {
            return;
          }
          confirmEarlyPaymentRequest.mutate();
        }}
      >
        {confirmEarlyPaymentRequest.isPending ? (
          <Loader className="animate-spin ml-2 h-4 w-4" />
        ) : (
          `Confirm`
        )}
      </Button>
    );
    isTermsNeeded = true;
  } else if (isWidgetEditable(invoice, isGC!)) {
    // If there is no confirm button, then show an "Update" button
    actionNodes.push(
      <Button
        className="w-full disabled:text-gray-500"
        onClick={() => {
          if (!checkIfTermsAccepted()) {
            return;
          }
          updateEarlyPaymentRequest.mutate();
        }}
        disabled={!isDiscountChanged}
      >
        {updateEarlyPaymentRequest.isPending ? (
          <Loader className="animate-spin ml-2 h-4 w-4" />
        ) : (
          `Update`
        )}
      </Button>
    );
    isTermsNeeded = true;
  }
  const [termsContainerRef] = useAutoAnimate();
  const shouldShowTerms =
    (isTermsNeeded && !!discount && isDiscountChanged) || isSCToConfirm;

  return (
    <>
      <div ref={termsContainerRef}>
        {shouldShowTerms && (
          <div className="mb-4">
            <TermsCheckbox
              checked={isAccepted}
              onChange={(e) => setIsAccepted(e.target.checked)}
              termsTextRawHtml={termsTextRawHtml}
              isShowingError={isShowingTermsError}
              setIsShowingTermsError={setIsShowingTermsError}
            />
          </div>
        )}
      </div>

      {requestEarlyPaymentRequest.isSuccess && (
        <div className="mb-4">
          <Alert variant={"success"}>
            <CheckCircleIcon className="h-4 w-4" />
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>Your early payment request has been submitted. We'll be in touch once
            the claim has been approved.</AlertDescription>
          </Alert>
        </div>
      )}

      {actionNodes.length > 0 && (
        <div className="flex flex-row flex-nowrap gap-2 justify-between w-full">
          {actionNodes.map((node, index) => (
            <Fragment key={index}>{node}</Fragment>
          ))}
        </div>
      )}
      {/* Always have modal here to prevent it from closing prematurely */}
      <IntegrationAutomationModal
        open={isIntegrationAutomationModalOpen}
        onOpenChange={(v) => {
          setIsIntegrationAutomationModalOpen(v);
        }}
        invoice={invoice}
        hasPrerequisite={true}
        isPrerequisiteSuccess={
          approveEarlyPaymentRequest.isPending
            ? undefined
            : approveEarlyPaymentRequest.isSuccess
        }
      ></IntegrationAutomationModal>
    </>
  );
};

export const RequestEarlyPaymentWidget = (prop: any) => {
  const { invoice }: { invoice: InvoiceResponse } = prop;
  const api = getApi();
  const meDetails = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<Date | null>(
    () => {
      if (invoice.discount?.payrun_date) {
        return moment(invoice.discount?.payrun_date).toDate();
      }
      return null;
    }
  );
  const [discount, setDiscount] = useState<string>(() =>
    invoice.discount?.discount ? `${invoice.discount?.discount}` : ""
  );

  const {
    original_payment_due_date,
    external_data: { net_claim_incl_tax },
  } = invoice;

  const isCalculationReady =
    Boolean(discount && !isNaN(Number(discount))) &&
    typeof selectedPaymentDate == "object";
  let earlyPaymentDiscount = null;
  let daysEarly = null;
  if (isCalculationReady) {
    earlyPaymentDiscount = discountCalculator(
      net_claim_incl_tax,
      {
        payrun_date: moment(selectedPaymentDate).format("YYYY-MM-DD"),
        discount: Number(discount),
      },
      10,
      invoice?.contract?.organisation?.settings?.pp_fee
    );
    daysEarly = moment(original_payment_due_date).diff(
      selectedPaymentDate,
      "days"
    );
  }
  // Should discount configuration be inherit from parent org?
  let discountsSetting = invoice.contract?.organisation?.discounts;
  if (!discountsSetting && invoice.contract?.organisation?.parent) {
    discountsSetting = invoice.contract?.organisation?.parent?.discounts;
  }
  const discountsConfiguration =
    discountsSetting && discountsSetting.payment_runs
      ? discountsSetting.payment_runs
      : [];
  const dynamicDiscountsConfiguration =
    discountsSetting?.dynamic_discounts ?? [];

  // When the invoice is pending approve in PAS, the original due date sometimes won't be available, hence show the warning message.
  const isPendingExternalApproval =
    invoice.original_payment_due_date === null &&
    invoice.external_data?.claim_status === "SUBMITTED" &&
    invoice.invoice_status === InvoiceStatusTypes.INTENDED;

  return discountsSetting ? (
    <div>
      {isPendingExternalApproval ? (
        <div>
          <Alert>
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>
              We have received your early payment intent.
            </AlertDescription>
            <AlertDescription>
              Claim pending approval in{" "}
              {invoice.contract?.organisation?.pas_system ?? ""}.
            </AlertDescription>
          </Alert>
        </div>
      ) : (
        <>
          <div
            className={cn(
              `rounded-lg p-6`,
              !isWidgetLightStyled(invoice, !!meDetails.data?.is_gc) &&
                `bg-slate-800 text-white`,
              isWidgetLightStyled(invoice, !!meDetails.data?.is_gc) &&
                `bg-gray-100`
            )}
          >
            <DiscountWidget
              type={(discountsSetting as DiscountsType).display_type}
              invoice={invoice}
              discountConfiguration={discountsConfiguration}
              dynamicDiscountConfiguration={dynamicDiscountsConfiguration}
              onChange={(discount) => {
                setDiscount(`${discount.discount}`);
                setSelectedPaymentDate(moment(discount.payrun_date).toDate());
              }}
            />
          </div>
          <div className="pt-4">
            <InvoiceAction
              discount={
                isCalculationReady
                  ? {
                      discount: Number(discount),
                      payrun_date: selectedPaymentDate
                        ? moment(selectedPaymentDate).format("YYYY-MM-DD")
                        : "",
                    }
                  : null
              }
              invoice={invoice}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="py-4 text-center">Discounts not configured.</div>
  );
};
