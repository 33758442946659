import { UserType } from "@progresspay-next/dtos";
import { ColumnsType } from "antd/es/table";
import { getApi } from "../../utils/api";
import { useQueryOrganisationById } from "../../utils/query";
import { useColumnSearchProps } from "../../utils/tables";
import { InviteUserButtonAndModal } from "../OrganisationProfile";
import { UsersListTable } from "../users/UsersList";

interface OrganisationsFormUsersTabProps {
  id: number|string;
}

export const OrganisationsFormUsersTab: (props:OrganisationsFormUsersTabProps) => JSX.Element | null = ({id}) => {
  const api = getApi();
  const targetOrganisation = useQueryOrganisationById(id);

  const columns: ColumnsType<UserType> = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => String(a.first_name).localeCompare(String(b.first_name)),
      sortDirections: ['descend'],
      ...useColumnSearchProps<UserType>('first_name'),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => String(a.last_name).localeCompare(String(b.last_name)),
      sortDirections: ['descend'],
      ...useColumnSearchProps<UserType>('last_name'),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    }
  ]

  return (<div>
    <div className="text-right">
      <InviteUserButtonAndModal organisationId={String(id)}></InviteUserButtonAndModal>
    </div>
    <UsersListTable users={targetOrganisation.data?.users} loading={targetOrganisation.isLoading}/>
  </div>)
}
