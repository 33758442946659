import { InvoiceStatusTypes, InvoiceType } from "@progresspay-next/dtos";
import { AgChartOptions } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import { GridApi } from "ag-grid-community";
import { LineChartIcon } from "lucide-react";
import moment from "moment";
import { useCallback, useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { formatCurrency, invoiceHelper } from "@progresspay-next/shared";

type InvoicesSummaryChartModalProps = {
  agGridApi: GridApi;
};

const transformInvoicesToDiscountsByMonth = (invoices: InvoiceType[]) => {
  const approved = invoices
    .filter(
      (invoice) => invoice.invoice_status === InvoiceStatusTypes.APPROVED && invoiceHelper.calculatedResult(invoice)
    )
    .sort((a, b) => {
      return moment(a.external_data.approved_at).diff(
        moment(b.external_data.approved_at)
      );
    });
  const grouped = approved.reduce(
    (acc, invoice) => {
      const month = moment(invoice.external_data.approved_at).format("MMM");
      const year = moment(invoice.external_data.approved_at).format("YYYY");
      const key = `${month} ${year}`;
      if (!acc[key]) {
        acc[key] = [1, invoiceHelper.calculatedResult(invoice)!.discountAmountInclTax];
      }
      acc[key][0] += 1;
      acc[key][1] += invoiceHelper.calculatedResult(invoice)!.discountAmountInclTax;
      return acc;
    },
    {} as Record<string, [number, number]>
  );
  const data = [];
  for (let key in grouped) {
    data.push({
      count: grouped[key][0],
      discount: grouped[key][1],
      month: key,
    });
  }
  return data;
};

const AGChart = (props: {
  data: ReturnType<typeof transformInvoicesToDiscountsByMonth>;
}) => {
  const [options, setOptions] = useState<AgChartOptions>({
    data: props.data,
    series: [
      {
        type: "bar",
        xKey: "month",
        yKey: "discount",
        yName: "Total Discount",
        tooltip: {
          renderer: ({ datum, xKey, yKey }) => {
            return {
              content: `${datum[xKey]}: ${formatCurrency(datum[yKey])}`,
              title: "Total Discount Amount",
            };
          },
        },
        fill: "#000"
      },
      {
        type: "line",
        xKey: "month",
        yKey: "count",
        yName: "Number of Invoices",
        tooltip: {
          renderer: ({ datum, xKey, yKey }) => {
            return {
              content: `${datum[xKey]}: ${datum[yKey].toFixed(0)}`,
              title: "Number of Invoices",
              backgroundColor: "#aaa",
            };
          },
        },
        stroke: `#aaa`,
        marker: {
          fill: "#aaa",
        },
      },
    ],
    axes: [
      { type: "category", position: "bottom" },
      {
        type: "number",
        position: "left",
        keys: ["discount"],
        label: {
          formatter: function (params) {
            return formatCurrency(params.value);
          },
        },
        title: {
          text: "Total Discount",
        },
      },
      {
        type: "number",
        position: "right",
        keys: ["count"],
        title: {
          text: "Number of Invoices",
        },
      },
    ],
  });
  return <AgChartsReact options={options} />;
};

export const InvoicesSummaryChartModal = (
  props: InvoicesSummaryChartModalProps
) => {
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);
  const onModalOpen = useCallback(() => {
    setInvoices([]);
    props.agGridApi.forEachNodeAfterFilter((node) => {
      setInvoices((invoices) => {
        return [...invoices, node.data];
      });
    });
  }, [props.agGridApi]);
  const discountsByMonth = transformInvoicesToDiscountsByMonth(invoices);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"ghost"} onClick={onModalOpen}>
          <LineChartIcon className="w-6 h-6" />
        </Button>
      </DialogTrigger>
      <DialogContent wide>
        <DialogHeader>
          <DialogTitle>Early Payment Summary By Month</DialogTitle>
          <div></div>

          <div className={`w-full mx-auto h-[clamp(200px,50vh,600px)] pt-10`}>
            <AGChart data={discountsByMonth} />
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
