import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useRememberFilterModel = (key: string) => {
  const location = useLocation();
  const storageKey = `@progrespay-next/ag-grid-${key}-${location.pathname}`;
  const getFilterModel = useCallback(() => {
    let filterModeString = null;
    try {
      filterModeString = window.localStorage.getItem(storageKey);
    } catch (e) {
    }
    return filterModeString ? JSON.parse(filterModeString) : null;
  }, [storageKey]);
  const setFilterModel = useCallback(
    (model: any) => {
      window.localStorage.setItem(storageKey, JSON.stringify(model));
    },
    [storageKey]
  );
  return {
    getFilterModel,
    setFilterModel,
  };
};