"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationType = void 0;
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["JOBPAC"] = "JOBPAC";
    IntegrationType["PAYAPPS"] = "PAYAPPS";
    IntegrationType["VISTA"] = "VISTA";
    IntegrationType["GCPAY"] = "GCPAY";
    IntegrationType["CHEOPS"] = "CHEOPS";
    IntegrationType["PROGRESSPAY"] = "PROGRESSPAY";
})(IntegrationType || (exports.IntegrationType = IntegrationType = {}));
