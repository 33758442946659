import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

import { Link } from "react-router-dom";

export const PageHeader = ({
  routes,
  extra,
}: {
  routes: {
    breadcrumbName: string;
    path?: string;
  }[];
  extra?: React.ReactNode | null;
}) => {
  return (
    <div className="pb-4 flex flex-row nowrap justify-between">
      <Breadcrumb>
        <BreadcrumbList>
          {routes.map((r, index) => {
            const last = index === routes.length - 1;
            if (last) {
              return (
                <React.Fragment key={JSON.stringify(r)}>
                  <BreadcrumbItem>{r.breadcrumbName}</BreadcrumbItem>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={JSON.stringify(r)}>
                  <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                      <Link to={r.path!}>{r.breadcrumbName}</Link>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                </React.Fragment>
              );
            }
          })}
        </BreadcrumbList>
      </Breadcrumb>
      {extra ? extra : null}
    </div>
  );
};
