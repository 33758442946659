import { LoginRequest } from "@progresspay-next/dtos";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  App,
  Button,
  Form,
  Input,
  Typography
} from "antd";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalStore } from "../stores/global";
import { getApi } from "../utils/api";

interface LoginProps {}
export const Login: (props: LoginProps) => JSX.Element | null = () => {
  const { message } = App.useApp();
  const store = useGlobalStore();
  const navigate = useNavigate();
  const api = getApi();
  // Auto redirect to homepage if access token is presented (it may be expired, so we need to check)
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
    enabled: !!store.accessToken,
  });
  useEffect(() => {
    if (me.data) {
      navigate("/home");
    }
  }, [me.data]) // eslint-disable-line react-hooks/exhaustive-deps
  const userLogin = useMutation(
    {
      mutationFn: (user: LoginRequest) => {
        return api.authUser(user);
      },
      onSuccess: (data) => {
        store.userLogin(data.access_token);
        message.success(`You have logged in successfully!`);
        navigate("/home");
      },
    }
  );
  const onFinish = (values: any) => {
    userLogin.mutate(values);
  };

  return (
    <>
      <div className="mb-8 flex items-center gap-4">
        <span className="text-3xl font-bold">Sign In to ProgressPay</span>
      </div>
      <Form
        className="w-full self-start"
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        {userLogin.isError ? (
          <Form.Item>
            <Typography.Paragraph type={"danger"}>
              An error occurred: {(userLogin.error as Error).message}
            </Typography.Paragraph>
          </Form.Item>
        ) : null}

        <Form.Item className="mb-0">
          <div className="flex flex-row flex-nowrap items-center justify-between">
            <Button
              size="large"
              loading={userLogin.isPending}
              type="primary"
              htmlType="submit"
            >
              Continue
            </Button>
            <Link to="/forgot-password">Forgot password?</Link>
          </div>

          {/* <Link to="/signup">
                <Button htmlType="button">Sign up</Button>
              </Link> */}
        </Form.Item>
      </Form>
    </>
  );
};
