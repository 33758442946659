"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationTransactionDataResponseSchema = void 0;
const zod_1 = require("zod");
const JobpacContractsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
const JobpacOrganisationsResponseSchema = zod_1.z.object({
    workIdListings: zod_1.z.any().array(),
    erp_id_key: zod_1.z.string()
});
const JobpacProjectsResponseSchema = zod_1.z.object({
    JobListing: zod_1.z.any().array(),
    erp_id_key: zod_1.z.string()
});
const JobpacSubcontractorsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
const JobpacClaimsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
exports.IntegrationTransactionDataResponseSchema = zod_1.z.object({
    payment_date: zod_1.z.string().nullable(),
    paid_amount: zod_1.z.number().nullable(),
    transation_id: zod_1.z.string().nullable(),
    pas_ref: zod_1.z.string().nullable(),
    is_posted_claim: zod_1.z.boolean(),
    payment_on_hold_ind: zod_1.z.boolean(),
});
