import { CalendarDatePicker } from "@/components/CalendarDatePicker";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { useContextStore } from "@/stores/context";
import { cn } from "@/utils/ui";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
    DiscountType,
    DiscountsType,
    DiscountsWidgetDisplayType,
    InvoiceResponse,
    InvoiceType,
} from "@progresspay-next/dtos";
import {
    datesHelper,
    discountCalculator,
    dynamicDiscountCalculator,
    formatCurrency,
    formatDate,
    invoiceHelper,
} from "@progresspay-next/shared";
import { SelectGroup, SelectLabel } from "@radix-ui/react-select";
import { Col, Row } from "antd";
import { animate, motion, useMotionValue, useTransform } from "framer-motion";
import { AlertTriangle, PercentIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
    isWidgetLightStyled
} from "./RequestEarlyPaymentWidget";

type FlexibleRateConfiguration = null;
type FixedRateConfiguration = DiscountType[];
type ProRataRateConfiguration = DiscountType[];

interface DiscountWidgetProp {
  type: DiscountsWidgetDisplayType;
  invoice: InvoiceResponse;
  discountConfiguration:
    | FlexibleRateConfiguration
    | FixedRateConfiguration
    | ProRataRateConfiguration;
  dynamicDiscountConfiguration?: DiscountsType["dynamic_discounts"];
  onChange?: (discount: DiscountType) => void;
}

const useAnimateCurrencyValue = (initialValue = 0, target: number) => {
  const revisedAmountInclTaxMotionValue = useMotionValue(initialValue)
  useEffect(() => {
    animate(revisedAmountInclTaxMotionValue, target);
  },[target, revisedAmountInclTaxMotionValue]);
  const revisedAmountInclTaxMotionString = useTransform(() => formatCurrency(revisedAmountInclTaxMotionValue.get()));
  return revisedAmountInclTaxMotionString;
}

const ItemWithLabel = ({
  label,
  item,
  disabled = false,
  className = "",
}: {
  label: React.ReactNode | null;
  item: React.ReactNode | null;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <div className={`${disabled ? `text-muted-foreground` : ``} ${className}`}>
      <div className="mb-2">{label}</div>
      <div className="text-base">{item}</div>
    </div>
  );
};

export const DiscountWidget = (prop: DiscountWidgetProp) => {
  const { type } = prop;
  if (type == DiscountsWidgetDisplayType.FIXED_RATE) {
    return <DiscountWidgetDropdown {...prop} />;
  } else if (type == DiscountsWidgetDisplayType.FLEXIBLE_RATE) {
    return <DiscountWidgetFreeEnter {...prop} />;
  } else if (type == DiscountsWidgetDisplayType.PRO_RATA_RATE) {
    return <DiscountWidgetProRata {...prop} />;
  }
  return null;
};

const DiscountWidgetFreeEnter = (prop: DiscountWidgetProp) => {
  const { invoice, onChange = () => {}, discountConfiguration } = prop;
  const stableOnchange = useRef(onChange);
  const context = useContextStore();
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<
    Date | undefined
  >(() => {
    if (invoice.discount?.payrun_date) {
      return moment(invoice.discount?.payrun_date).toDate();
    }
    return undefined;
  });
  const [discount, setDiscount] = useState<string>(() =>
    invoice.discount?.discount ? `${invoice.discount?.discount}` : ""
  );

  const {
    original_payment_due_date,
    external_data: { net_claim_incl_tax },
  } = invoice;

  const isCalculationReady =
    typeof selectedPaymentDate == "object" &&
    Boolean(discount && !isNaN(Number(discount)));


  let earlyPaymentDiscount = invoiceHelper.calculatedResult(invoice as InvoiceType);
  let daysEarly =
    earlyPaymentDiscount?.revisedDueDate && original_payment_due_date
      ? datesHelper.daysBefore(
          earlyPaymentDiscount.revisedDueDate,
          original_payment_due_date
        )
      : null;
  if (isCalculationReady) {
    earlyPaymentDiscount = discountCalculator(
      net_claim_incl_tax,
      {
        payrun_date: moment(selectedPaymentDate).format("YYYY-MM-DD"),
        discount: Number(discount),
      },
      10,
      invoice?.contract?.organisation?.settings?.pp_fee
    );
    daysEarly = original_payment_due_date
      ? datesHelper.daysBefore(selectedPaymentDate, original_payment_due_date)
      : null;
  }
  useEffect(() => {
    if (isCalculationReady) {
      stableOnchange.current({
        discount: Number(discount),
        payrun_date: moment(selectedPaymentDate).format("YYYY-MM-DD"),
      });
    }
  }, [discount, selectedPaymentDate, isCalculationReady]);
  const disabledMatcher = (date: Date) => {
    if (discountConfiguration && Array.isArray(discountConfiguration)) {
      let found = false;
      for (let d of discountConfiguration as FixedRateConfiguration) {
        if (moment(date).isSame(d.payrun_date, "day")) {
          // If the payrun date falls before the original due date
          if (
            original_payment_due_date &&
            moment(date).isBefore(original_payment_due_date, "day")
          ) {
            found = true;
          }
          // Might be in the preview mode
          if (!original_payment_due_date) {
            found = true;
          }
        }
      }
      return !found;
    }
    return true;
  };

  const isWidgetLightStyledValue = isWidgetLightStyled(
    invoice,
    !!context.me?.is_gc
  );

  return (
    <>
      {isWidgetLightStyledValue ? (
        <>
          <div className="mb-6 py-2 text-3xl">Discount Details</div>
        </>
      ) : (
        <>
          <div className="mb-6 py-2 text-3xl">
            Choose when you want to get paid
          </div>
          <Row gutter={8} className="mb-6">
            <Col span={12}>
              <ItemWithLabel
                label={`Early Payment Date`}
                item={
                  <>
                    <div className="mb-1 bg-white bg-opacity-5">
                      <CalendarDatePicker
                        fromDate={new Date()}
                        value={selectedPaymentDate}
                        onChange={setSelectedPaymentDate}
                        disabled={disabledMatcher}
                      />
                    </div>
                    <p className="text-xs text-muted-foreground">
                      Payment Due Date:{" "}
                      {moment(original_payment_due_date).format(`Do MMM YYYY`)}
                    </p>
                  </>
                }
              />
            </Col>
            <Col span={12}>
              <ItemWithLabel
                label={`Discount Rate`}
                item={
                  <div>
                    <div className={cn("relative")}>
                      <PercentIcon
                        className={cn(
                          "absolute right-2 top-1/2 w-4 -translate-y-1/2"
                        )}
                      />
                      <Input
                        className={cn("mb-1 w-full bg-white bg-opacity-5")}
                        value={discount}
                        onChange={(e) => {
                          if (
                            (e.target.validity.valid &&
                              Number(e.target.value) > 0 &&
                              Number(e.target.value) <= 100) ||
                            e.target.value === ""
                          ) {
                            setDiscount(e.target.value);
                          }
                          e.preventDefault();
                        }}
                        pattern="^\d*(\.\d{0,2})?$"
                      ></Input>
                    </div>
                    <p className="text-xs text-muted-foreground">
                      Enter preferred discount
                    </p>
                  </div>
                }
              />
            </Col>
          </Row>
        </>
      )}

      <Row gutter={8} className="mb-6">
        <Col span={24}>
          <ItemWithLabel
            label={`Total you will receive`}
            item={
              <div className="text-5xl">
                {earlyPaymentDiscount
                  ? formatCurrency(earlyPaymentDiscount.revisedAmountInclTax)
                  : `$-`}
              </div>
            }
            disabled={!isCalculationReady}
          />
        </Col>
      </Row>
      <Row
        gutter={16}
        className={cn(
          `mb-2 border-b border-t py-4`,
          !isWidgetLightStyledValue && `border-gray-500`,
          isWidgetLightStyledValue && `border-gray-300`
        )}
      >
        <Col span={8}>
          <ItemWithLabel
            className={cn(
              `border-r`,
              !isWidgetLightStyledValue && `border-gray-500`,
              isWidgetLightStyledValue && `border-gray-300`
            )}
            label={`Days Paid Early`}
            item={<div className="">{daysEarly ? daysEarly : `-`}</div>}
            disabled={!isCalculationReady}
          />
        </Col>
        <Col span={8}>
          <ItemWithLabel
            className={cn(
              `border-r`,
              !isWidgetLightStyledValue && `border-gray-500`,
              isWidgetLightStyledValue && `border-gray-300`
            )}
            label={`Total Discount`}
            item={
              <div className="">
                {earlyPaymentDiscount
                  ? formatCurrency(earlyPaymentDiscount.discountAmountInclTax)
                  : `$-`}
              </div>
            }
            disabled={!isCalculationReady}
          />
        </Col>
        <Col span={8}>
          <ItemWithLabel
            label={`Discount Rate`}
            item={
              <div className="">
                {earlyPaymentDiscount?.discountAmountPercentage
                  ? `${earlyPaymentDiscount.discountAmountPercentage.toFixed(
                      2
                    )}%`
                  : `-%`}
              </div>
            }
            disabled={!isCalculationReady}
          />
        </Col>
      </Row>
    </>
  );
};

const DiscountWidgetDropdown = (prop: DiscountWidgetProp) => {
  const { invoice, onChange = () => {}, discountConfiguration = [] } = prop;
  const stableOnchange = useRef(onChange);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<string>();
  const [discount, setDiscount] = useState<number>();
  const context = useContextStore();

  const {
    original_payment_due_date,
    external_data: { net_claim_incl_tax },
  } = invoice;

  const isCalculationReady =
    selectedPaymentDate && discount && !isNaN(Number(discount));
  let earlyPaymentDiscount = invoiceHelper.calculatedResult(invoice as InvoiceType);
  let daysEarly =
    earlyPaymentDiscount?.revisedDueDate && original_payment_due_date
      ? datesHelper.daysBefore(
          earlyPaymentDiscount.revisedDueDate,
          original_payment_due_date
        )
      : null;
  if (isCalculationReady) {
    earlyPaymentDiscount = discountCalculator(
      net_claim_incl_tax,
      {
        payrun_date: selectedPaymentDate,
        discount: Number(discount),
      },
      10,
      invoice?.contract?.organisation?.settings?.pp_fee
    );
    daysEarly = original_payment_due_date
      ? datesHelper.daysBefore(selectedPaymentDate, original_payment_due_date)
      : null;
  }
  useEffect(() => {
    if (isCalculationReady) {
      stableOnchange.current({
        discount: Number(discount),
        payrun_date: selectedPaymentDate as string,
      });
    }
  }, [discount, selectedPaymentDate, isCalculationReady]);

  const discountsOptions = (
    discountConfiguration as FixedRateConfiguration
  ).map((d) => ({
    payrunDate: d.payrun_date,
    discount: d.discount,
  }));

  const isWidgetLightStyledValue = isWidgetLightStyled(
    invoice,
    !!context.me?.is_gc
  );

  const [animateContainerRef] = useAutoAnimate();

  const revisedAmountInclTaxMotionString = useAnimateCurrencyValue(0, earlyPaymentDiscount?.revisedAmountInclTax ?? 0);
  const discountAmountInclTaxMotionString = useAnimateCurrencyValue(0, earlyPaymentDiscount?.discountAmountInclTax ?? 0);

  // Initialise payment date & discount if it's already set
  useEffect(() => {
    if (invoice.discount?.payrun_date && invoice.discount?.payrun_date)  {
      setSelectedPaymentDate(invoice.discount?.payrun_date);
      setDiscount(invoice.discount?.discount);
    }
  },[invoice.discount])

  return (
    <>
      {isWidgetLightStyledValue ? (
        <div className="mb-6 py-2 text-3xl">Discount Details</div>
      ) : (
        <>
          <div className="mb-6 py-2 text-3xl">
            Choose when you want to get paid
          </div>
          <div className="mb-6">
            <Select
              onValueChange={(paymentDate) => {
                const findDiscount = (
                  discountConfiguration as FixedRateConfiguration
                ).find((d) => d.payrun_date == paymentDate)?.discount;
                if (typeof findDiscount == "number") {
                  setDiscount(findDiscount);
                  setSelectedPaymentDate(paymentDate);
                }
              }}
              value={selectedPaymentDate}
            >
              <SelectTrigger className="w-full">
                <SelectValue
                  placeholder="Payment Date"
                  aria-label={selectedPaymentDate}
                >
                  <span>{formatDate(selectedPaymentDate as string)}</span>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    <div className="flex w-full flex-row flex-nowrap font-bold">
                      <div className="flex-1 py-2 px-8">Early Payment Date</div>
                      <div className="flex-1 py-2 px-4 text-right">
                        Discount
                      </div>
                    </div>
                  </SelectLabel>
                  {discountsOptions
                    .sort((a, b) =>
                      moment(a.payrunDate).isAfter(moment(b.payrunDate))
                        ? 1
                        : -1
                    )
                    .map((d) => {
                      const isDateBeforeDueDate = moment(d.payrunDate).isBefore(
                        invoice.original_payment_due_date
                      );
                      const isDateInTheFuture = moment(d.payrunDate).isAfter(
                        moment()
                      );
                      return isDateBeforeDueDate && isDateInTheFuture ? (
                        <SelectItem
                          key={d.payrunDate}
                          value={`${d.payrunDate}`}
                          className="w-full"
                        >
                          <div className="flex w-full flex-row flex-nowrap">
                            <div className="flex-1">
                              {formatDate(d.payrunDate)}
                            </div>
                            <div className="flex-1 px-2 text-right">
                              {d.discount}%
                            </div>
                          </div>
                        </SelectItem>
                      ) : null;
                    })}
                  <SelectLabel>
                    <div className="flex w-full flex-row flex-nowrap text-sm">
                      <div className="flex-1 px-8 py-2">
                        {original_payment_due_date
                          ? formatDate(original_payment_due_date)
                          : "Original Due Date N/A"}
                        <span className="text-gray-400"> (Current)</span>
                      </div>
                      <div className="flex-1 px-4 py-2 text-right">0%</div>
                    </div>
                  </SelectLabel>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </>
      )}

      <div ref={animateContainerRef}>
        {(isCalculationReady || isWidgetLightStyledValue) && (
          <>
            <Row gutter={8} className="mb-6">
              <Col span={24}>
                <ItemWithLabel
                  label={`Total you will receive`}
                  item={
                    <motion.div className="text-5xl">
                      {earlyPaymentDiscount
                        ? revisedAmountInclTaxMotionString
                        : `$-`}
                    </motion.div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              className="mb-2 border-b border-t border-gray-500 py-4"
            >
              <Col span={8}>
                <ItemWithLabel
                  className={cn(
                    `border-r`,
                    !isWidgetLightStyledValue && `border-gray-500`,
                    isWidgetLightStyledValue && `border-gray-300`
                  )}
                  label={`Days Paid Early`}
                  item={<div className="">{daysEarly ? daysEarly : `-`}</div>}
                  disabled={!isCalculationReady}
                />
              </Col>
              <Col span={8}>
                <ItemWithLabel
                  className={cn(
                    `border-r`,
                    !isWidgetLightStyledValue && `border-gray-500`,
                    isWidgetLightStyledValue && `border-gray-300`
                  )}
                  label={`Total Discount`}
                  item={
                    <motion.div className="">
                      {earlyPaymentDiscount
                        ? discountAmountInclTaxMotionString
                        : `$-`}
                    </motion.div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
              <Col span={8}>
                <ItemWithLabel
                  label={`Discount Rate`}
                  item={
                    <div className="">
                      {earlyPaymentDiscount?.discountAmountPercentage
                        ? `${earlyPaymentDiscount.discountAmountPercentage.toFixed(
                            2
                          )}%`
                        : `-%`}
                    </div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

const DiscountWidgetProRata = (prop: DiscountWidgetProp) => {
  const {
    invoice,
    onChange = () => {},
    discountConfiguration = [],
    dynamicDiscountConfiguration = [],
  } = prop;
  const stableOnchange = useRef(onChange);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<string>();
  const [discount, setDiscount] = useState<number>();
  const context = useContextStore();

  const {
    original_payment_due_date,
    external_data: { net_claim_incl_tax },
  } = invoice;

  const isCalculationReady =
    selectedPaymentDate && discount && !isNaN(Number(discount));
  let earlyPaymentDiscount = invoiceHelper.calculatedResult(invoice as InvoiceType);
  let daysEarly =
    earlyPaymentDiscount?.revisedDueDate && original_payment_due_date
      ? datesHelper.daysBefore(
          earlyPaymentDiscount.revisedDueDate,
          original_payment_due_date
        )
      : null;
  if (isCalculationReady) {
    earlyPaymentDiscount = discountCalculator(
      net_claim_incl_tax,
      {
        payrun_date: selectedPaymentDate,
        discount: Number(discount),
      },
      10,
      invoice?.contract?.organisation?.settings?.pp_fee
    );
    daysEarly = original_payment_due_date
      ? datesHelper.daysBefore(selectedPaymentDate, original_payment_due_date)
      : null;
  }
  useEffect(() => {
    if (isCalculationReady) {
      stableOnchange.current({
        discount: Number(discount),
        payrun_date: selectedPaymentDate as string,
      });
    }
  }, [discount, selectedPaymentDate, isCalculationReady]);

  const discountsOptions = (
    (discountConfiguration as ProRataRateConfiguration) ?? []
  ).map((d) => ({
    payrunDate: d.payrun_date,
  }));
  const dynamicDiscountsConfig = dynamicDiscountConfiguration ?? [];

  const isWidgetLightStyledValue = isWidgetLightStyled(
    invoice,
    !!context.me?.is_gc
  );

  const isOriginalPaymentDatePresented = !!invoice.original_payment_due_date;

  const [animateContainerRef] = useAutoAnimate();

  const revisedAmountInclTaxMotionString = useAnimateCurrencyValue(0, earlyPaymentDiscount?.revisedAmountInclTax ?? 0);
  const discountAmountInclTaxMotionString = useAnimateCurrencyValue(0, earlyPaymentDiscount?.discountAmountInclTax ?? 0);

  // Initialise payment date & discount if it's already set
  useEffect(() => {
    if (invoice.discount?.payrun_date && invoice.discount?.payrun_date)  {
      setSelectedPaymentDate(invoice.discount?.payrun_date);
      setDiscount(invoice.discount?.discount);
    }
  },[invoice.discount])

  return isOriginalPaymentDatePresented ? (
    <>
      {isWidgetLightStyledValue ? (
        <div className="mb-6 py-2 text-3xl">Discount Details</div>
      ) : (
        <>
          <div className="mb-6 py-2 text-3xl">
            Choose when you want to get paid
          </div>
          <div className="mb-6">
            <Select
              onValueChange={(paymentDate) => {
                setDiscount(
                  dynamicDiscountCalculator(
                    invoice.original_payment_due_date!,
                    paymentDate,
                    dynamicDiscountsConfig
                  ).discount
                );
                setSelectedPaymentDate(paymentDate);
              }}
              value={selectedPaymentDate}
            >
              <SelectTrigger className="w-full">
                <SelectValue
                  placeholder="Payment Date"
                  aria-label={selectedPaymentDate}
                >
                  <span>{formatDate(selectedPaymentDate as string)}</span>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    <div className="flex w-full flex-row flex-nowrap font-bold">
                      <div className="flex-1 py-2 px-8">Early Payment Date</div>
                      <div className="flex-1 py-2 px-4 text-right">
                        Discount
                      </div>
                    </div>
                  </SelectLabel>
                  {discountsOptions
                    .sort((a, b) =>
                      moment(a.payrunDate).isAfter(moment(b.payrunDate))
                        ? 1
                        : -1
                    )
                    .map((d) => {
                      const isDateBeforeDueDate = moment(d.payrunDate).isBefore(
                        invoice.original_payment_due_date
                      );
                      const isDateInTheFuture = moment(d.payrunDate).isAfter(
                        moment()
                      );
                      return isDateBeforeDueDate && isDateInTheFuture ? (
                        <SelectItem
                          key={d.payrunDate}
                          value={`${d.payrunDate}`}
                          className="w-full"
                        >
                          <div className="flex w-full flex-row flex-nowrap">
                            <div className="flex-1">
                              {formatDate(d.payrunDate)}
                              <span className="text-gray-400">
                                {" "}
                                (
                                {moment(
                                  invoice.original_payment_due_date!
                                ).diff(d.payrunDate, "days")}{" "}
                                days){" "}
                              </span>
                            </div>
                            <div className="flex-1 px-2 text-right">
                              {dynamicDiscountCalculator(
                                invoice.original_payment_due_date!,
                                d.payrunDate,
                                dynamicDiscountsConfig
                              ).discount.toLocaleString("en-AU", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 0,
                              })}
                              %
                            </div>
                          </div>
                        </SelectItem>
                      ) : null;
                    })}
                  <SelectLabel>
                    <div className="flex w-full flex-row flex-nowrap text-sm">
                      <div className="flex-1 px-8 py-2">
                        {original_payment_due_date
                          ? formatDate(original_payment_due_date)
                          : "Original Due Date N/A"}
                        <span className="text-gray-400"> (Current)</span>
                      </div>
                      <div className="flex-1 px-4 py-2 text-right">0%</div>
                    </div>
                  </SelectLabel>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </>
      )}

      <div ref={animateContainerRef}>
        {(isCalculationReady || isWidgetLightStyledValue) && (
          <>
            <Row gutter={8} className="mb-6">
              <Col span={24}>
                <ItemWithLabel
                  label={`Total you will receive`}
                  item={
                    <motion.div className="text-5xl">
                      {earlyPaymentDiscount
                        ? revisedAmountInclTaxMotionString
                        : `$-`}
                    </motion.div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              className="mb-2 border-b border-t border-gray-500 py-4"
            >
              <Col span={8}>
                <ItemWithLabel
                  className={cn(
                    `border-r`,
                    !isWidgetLightStyledValue && `border-gray-500`,
                    isWidgetLightStyledValue && `border-gray-300`
                  )}
                  label={`Days Paid Early`}
                  item={<div className="">{daysEarly ? daysEarly : `-`}</div>}
                  disabled={!isCalculationReady}
                />
              </Col>
              <Col span={8}>
                <ItemWithLabel
                  className={cn(
                    `border-r`,
                    !isWidgetLightStyledValue && `border-gray-500`,
                    isWidgetLightStyledValue && `border-gray-300`
                  )}
                  label={`Total Discount`}
                  item={
                    <motion.div className="">
                      {earlyPaymentDiscount
                        ? discountAmountInclTaxMotionString
                        : `$-`}
                    </motion.div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
              <Col span={8}>
                <ItemWithLabel
                  label={`Discount Rate`}
                  item={
                    <div className="">
                      {earlyPaymentDiscount?.discountAmountPercentage
                        ? `${earlyPaymentDiscount.discountAmountPercentage.toFixed(
                            2
                          )}%`
                        : `-%`}
                    </div>
                  }
                  disabled={!isCalculationReady}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  ) : (
    <div className="flex flew-row flex-nowrap items-center gap-2">
      <div className="flex-none"><AlertTriangle className="h-4 w-4" /></div>
      <div className="flex-1">Original payment date is not found.</div>
    </div>
  );
};
