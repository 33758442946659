import { InvoiceResponse, InvoiceType } from "@progresspay-next/dtos"
import { DataSection } from "./DataSection"
import { formatCurrency, invoiceHelper } from "@progresspay-next/shared";
import moment from "moment";
import { useContextStore } from "@/stores/context";

export const EarlyPaymentRequestSummary = ({
  invoice
}:{
  invoice: InvoiceResponse
}) => {
  const contextStore = useContextStore();
  const isGC = contextStore?.me?.is_gc;
  const invoiceCalculator = invoiceHelper.calculatedResult(invoice as InvoiceType);
  if (!invoiceCalculator) {
    return null
  }
  const data: [string,string][] = [
    [`New Invoice Value`, formatCurrency(invoiceCalculator.revisedAmountInclTax)],
    [isGC ? `Total Discount Secured` : `Total Discount`, formatCurrency(invoiceCalculator.discountAmountInclTax)],
    [`New Due Date`, moment(invoiceCalculator.revisedDueDate).format(`Do MMM YYYY`)],
    [`Discount Rate`, invoiceCalculator.discountAmountPercentage ? `${invoiceCalculator.discountAmountPercentage.toFixed(2)}%`:`-%`],
  ];

  return <DataSection data={data} title="Request Details" />
}
