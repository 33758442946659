"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EligibleInvoicesResponseSchema = exports.EligibleInvoiceResponseSchema = exports.ActivitiesResponseSchema = exports.InvoicesSummaryResponseSchema = exports.InvoicesResponseSchema = exports.InvoiceResponseSchema = void 0;
const zod_1 = require("zod");
const activity_type_1 = require("../types/activity.type");
const invoice_type_1 = require("../types/invoice.type");
const user_type_1 = require("../types/user.type");
exports.InvoiceResponseSchema = invoice_type_1.InvoiceTypeSchema.omit({
    discounts_snapshot: true,
}).extend({});
exports.InvoicesResponseSchema = exports.InvoiceResponseSchema.array();
exports.InvoicesSummaryResponseSchema = zod_1.z.object({
    facility_remaining: zod_1.z.number(),
    requested: zod_1.z.any(),
    approved: zod_1.z.any(),
});
exports.ActivitiesResponseSchema = activity_type_1.ActivityTypeSchema.extend({
    user: user_type_1.UserTypeSchema
}).array();
exports.EligibleInvoiceResponseSchema = invoice_type_1.EligibleInvoiceSchema.extend({});
exports.EligibleInvoicesResponseSchema = invoice_type_1.EligibleInvoiceSchema.merge(zod_1.z.object({
    originalDueDate: zod_1.z.null(),
})).array();
