import { PageHeader } from "@/components/PageHeader";
import { useContextStore } from "@/stores/context";
import { UpdateUserRequest, UserType } from "@progresspay-next/dtos";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  Form,
  Input,
  Spin,
  Switch,
  Tabs
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageTitleSlotType,
  useInternalLayoutContext,
} from "../../components/layouts/InternalLayout";
import { getApi } from "../../utils/api";

interface UsersFormProps {
  id?: string | null;
  context?: "drawer" | "page";
  onActionSuccess?: () => void;
  isAdminForm?: boolean;
}

export const UsersForm: (props: UsersFormProps) => JSX.Element | null = ({
  id,
  context = "page",
  onActionSuccess,
  isAdminForm = true,
}) => {
  const { message } = App.useApp();
  const urlParams = useParams();
  const api = getApi();
  const navigate = useNavigate();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const contextStore = useContextStore();

const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    if (context === "page") {
      setPageTitleSlotProps && setPageTitleSlotProps({
        type: PageTitleSlotType.SimplePageTitle,
        props: {
          title: "Edit User",
        },
      });
    }
  }, [context, setPageTitleSlotProps]);

  const createUser = useMutation({
    mutationFn: (payload) => {
      return new Promise((resolve, reject) => {
        message.error(`This action is not supported`);
        resolve(null);
      });
    },
    onSuccess: (data, variables, context) => {
      message.success("Your organisation has been created successfully.");
      onActionSuccess && onActionSuccess();
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
      onError: () => {
      message.error("There was an error when creating the organisation.");
    },
  });

  const updateUser = useMutation({
    mutationFn: (payload: UpdateUserRequest) => {
      if (isAdminForm) {
        return api.updateUsers(payload);
      } else {
        return api.updateUser(payload);
      }
    },
    onSuccess: (data, variables, context) => {
      message.success("This user has been updated successfully.");
      onActionSuccess && onActionSuccess();
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (data.id === contextStore.me?.id) {
        queryClient.invalidateQueries({ queryKey: ["me"] });
      }
    },
    onError: () => {
      message.error("Organisation update failed.");
    },
  });

  if (context == "page") {
    id = urlParams.id;
  }
  const isNew = !id;
  const targetUser = useQuery({
    queryKey: ["users", { id }],
    queryFn: () => {
      if (id) {
        if (isAdminForm) {
          return api.findUser(id as any);
        } else {
          return api.findUserNonAdmin(id);
        }
      }
      return null;
    },
  });
  useEffect(() => {
    if (id && targetUser.data) {
      for (let k in targetUser.data) {
        form.setFieldValue(k, targetUser.data[k as keyof UserType]);
      }
    }
  }, [targetUser.data, form, id]);

  const routes = [
    {
      path: "/home",
      breadcrumbName: "Home",
    },
    {
      path: "/admin/users",
      breadcrumbName: "Users",
    },
  ];
  if (isNew) {
    routes.push({
      path: "/admin/users/new",
      breadcrumbName: "Create User",
    });
  } else {
    routes.push({
      path: `/admin/users/edit/${id}`,
      breadcrumbName: "Edit User",
    });
  }

  const initialValues = {
    id,
    disabled_at: "",
    email: "",
    first_name: "",
    last_name: "",
    mobile: "",
    organisation_id: "",
    phone: "",
    pas_id: "",
  };
  const handleSubmit = (values: any) => {
    if (isNew) {
      createUser.mutate(values);
    } else {
      updateUser.mutate(values);
    }
  };

  const mainFormContent = targetUser.isSuccess ? (
    <Form
      form={form}
      layout={"horizontal"}
      labelCol={{ span: 6 }}
      labelAlign="left"
      initialValues={initialValues}
      onFinish={handleSubmit}
      autoComplete="off"
    >
      {isNew ? null : (
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
      )}

      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "Please input your first name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "Please input your last name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Mobile" name="mobile">
        <Input />
      </Form.Item>

      <Form.Item label="Phone" name="phone">
        <Input />
      </Form.Item>

      <Form.Item label="PAS ID" name="pas_id">
        <Input />
      </Form.Item>

      <Form.Item
        label="Organisation Admin"
        name="is_org_admin"
        valuePropName="checked"
      >
        <Switch className="bg-slate-400" />
      </Form.Item>

      <Form.Item
        label="Finance Admin"
        name="is_fin_admin"
        valuePropName="checked"
      >
        <Switch className="bg-slate-400" />
      </Form.Item>

      <Form.Item>
        <Button shape="round" type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <Spin />
  );

  const tabItems = [
    {
      label: "Basic",
      key: "basic",
      children: mainFormContent,
    },
  ];

  return (
    <div>
      {context == "page" ? (
        <div>
          <PageHeader routes={routes}></PageHeader>
        </div>
      ) : null}

      {tabItems.length > 1 ? (
        <Tabs type="card" items={tabItems} />
      ) : (
        tabItems[0].children
      )}
    </div>
  );
};
