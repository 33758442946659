import { DiscountWidget } from "@/components/invoices/DiscountWidget";
import { DocumentsSection } from "@/components/invoices/DocumentsSection";
import { InvoiceStatusTag } from "@/components/invoices/InvoiceStatusTag";
import {
  PageTitleSlotType,
  useInternalLayoutContext,
} from "@/components/layouts/InternalLayout";
import LabelAndValuePairs from "@/components/misc/LabelAndValuePairs";
import { TermsCheckbox } from "@/components/misc/TermsCheckbox";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useContextStore } from "@/stores/context";
import { getApi } from "@/utils/api";
import { eligibleInvoiceToInvoice } from "@/utils/shims/eligibleInvoiceToInvoice";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { invoiceHelper, momentjsFormat } from "@progresspay-next/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Spin, Tabs } from "antd";
import currency from "currency.js";
import { AlertTriangle, ChevronLeftIcon, Loader } from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  queryKey,
  useQueryEligibleInvoiceByIdNonAdmin,
} from "../../utils/query";

type EligibleInvoiceEmbeddedProps = {};

const EligibleInvoiceRequestEarlyPaymentFooter = ({
  pasId,
  discount,
}: {
  pasId: string;
  discount:
    | {
        payrun_date: string;
        discount: number;
      }
    | undefined;
}) => {
  const { message } = App.useApp();
  const api = getApi();
  const client = useQueryClient();
  const navigate = useNavigate();

  const eligibleInvoiceQuery = useQueryEligibleInvoiceByIdNonAdmin(pasId, {
    staleTime: Infinity,
  });
  const handleSuccess = (id: string) => {
    client.invalidateQueries({
      queryKey: queryKey.findEligibleInvoicesNonAdmin(),
    });
    client.invalidateQueries({ queryKey: queryKey.invoicesNonAdmin() });
    navigate(`/embed/dashboard?tab=eligible`, {
      state: {
        approvedId: id,
        originalStatus: eligibleInvoiceQuery.data?.status,
      },
    });
  };
  const [isAccepted, setIsAccepted] = useState(false);
  const [isShowingTermsError, setIsShowingTermsError] = useState(false);

  const eligibleInvoiceDetailQuery = useQueryEligibleInvoiceByIdNonAdmin(pasId);
  const termsTextRawHtml = invoiceHelper.generateTermsTextRawHtml(
    {
      scName: eligibleInvoiceDetailQuery.data?.scName || "",
      gcName: eligibleInvoiceDetailQuery.data?.gcName || "",
    },
    eligibleInvoiceDetailQuery.data?.status === "APPROVED"
  );

  const requestMutation = useMutation({
    mutationFn: api.requestEarlyPaymentOnEligibleInvoice,
    onSuccess: (response) => {
      handleSuccess(response.id);
    },
    onError: (error: Error) => {
      message.error(error.message);
    },
  });
  const [termsContainerRef] = useAutoAnimate();

  const shouldSummitAsIntentAsIs =
    !eligibleInvoiceDetailQuery.data?.originalDueDate;
  const [
    isEarlyPaymentIntentCheckboxChecked,
    setIsEarlyPaymentIntentCheckboxChecked,
  ] = useState(false);
  return (
    <div>
      <div ref={termsContainerRef}>
        {!!discount && (
          <div className="mb-4">
            <TermsCheckbox
              checked={isAccepted}
              onChange={(e) => setIsAccepted(e.target.checked)}
              termsTextRawHtml={termsTextRawHtml}
              isShowingError={isShowingTermsError}
              setIsShowingTermsError={setIsShowingTermsError}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row flex-nowrap gap-4">
        {shouldSummitAsIntentAsIs ? (
          <div className="w-full">
            <div className="py-3 flex flex-row gap-2 items-center">
              <Checkbox
                id="submit-early-payment-intent-checkbox"
                checked={isEarlyPaymentIntentCheckboxChecked}
                onCheckedChange={(v) => {
                  if (v === true || v === false) {
                    setIsEarlyPaymentIntentCheckboxChecked(v);
                  }
                }}
              ></Checkbox>
              <label htmlFor="submit-early-payment-intent-checkbox">
                I'm interested in early payment
              </label>
            </div>
            <div>
              <Button
                className="w-full bg-gray-800"
                onClick={() => {
                  requestMutation.mutate({
                    pas_id: pasId,
                  });
                }}
                disabled={
                  requestMutation.isPending ||
                  !isEarlyPaymentIntentCheckboxChecked
                }
              >
                Notify
                {requestMutation.isPending ? (
                  <Loader className="animate-spin ml-2 h-4 w-4" />
                ) : null}
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className="w-full bg-gray-800"
            onClick={() => {
              if (!isAccepted) {
                setIsShowingTermsError(true);
                return;
              }
              requestMutation.mutate({
                pas_id: pasId,
                discount: discount!,
                terms_accepted: isAccepted,
              });
            }}
            disabled={!discount || requestMutation.isPending || !isAccepted}
          >
            Request Early Payment
            {requestMutation.isPending ? (
              <Loader className="animate-spin ml-2 h-4 w-4" />
            ) : null}
          </Button>
        )}
      </div>
    </div>
  );
};

export const EligibleInvoiceEmbedded: (
  props: EligibleInvoiceEmbeddedProps
) => JSX.Element | null = () => {
  const { id: pasId } = useParams<{ id: string }>();
  const queryCient = useQueryClient();
  const [chosenDiscount, setChosenDiscount] = useState<{
    payrun_date: string;
    discount: number;
  }>();
  const eligibleInvoiceDetailQuery = useQueryEligibleInvoiceByIdNonAdmin(
    pasId as string,
    {
      staleTime: Infinity,
    }
  );
  const detailsData = [
    { label: "Builder", value: eligibleInvoiceDetailQuery.data?.gcName },
    {
      label: "Payapps Status",
      value: eligibleInvoiceDetailQuery.data?.status,
    },
    { label: "Project", value: eligibleInvoiceDetailQuery.data?.project },
    { label: "Contract", value: eligibleInvoiceDetailQuery.data?.contract },
    {
      label: "Invoice Number",
      value: eligibleInvoiceDetailQuery.data?.invoiceNumber,
    },
    {
      label: "Amount (incl. GST)",
      value: currency(
        eligibleInvoiceDetailQuery.data?.amountIncludingGST as number
      ).format(),
    },
    {
      label: "Original Due Date",
      value: eligibleInvoiceDetailQuery.data?.originalDueDate
        ? moment(
            eligibleInvoiceDetailQuery.data?.originalDueDate,
            momentjsFormat.dateDB
          ).format(momentjsFormat.date)
        : "Standard Terms",
    },
  ];
  const tabItems = [
    {
      key: "1",
      label: "Invoice Details",
      children: (
        <div className="-mt-4 rounded-b-lg border-b border-l border-r bg-white p-6 text-base">
          <LabelAndValuePairs data={detailsData}></LabelAndValuePairs>
        </div>
      ),
    },
  ];

  // Do not show discount widget if there are no discounts configured
  // Also do not show discount widget if it's in submitted state
  const isShowingDiscountReady = !!eligibleInvoiceDetailQuery.data?.discounts;
  const eligibleInvoiceShimedToInvoice = eligibleInvoiceDetailQuery.data
    ? eligibleInvoiceToInvoice(eligibleInvoiceDetailQuery.data)
    : null;

  const { isEmbedded } = useContextStore();

  const setPageTitleSlotProps =
    useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    if (!isEmbedded) {
      setPageTitleSlotProps &&
        setPageTitleSlotProps({
          type: PageTitleSlotType.SimplePageTitle,
          props: {
            title: "Early Payment Request - Eligible Invoice",
          },
        });
    }
  }, [isEmbedded, setPageTitleSlotProps]);

  const shouldSummitAsIntentAsIs =
    !eligibleInvoiceDetailQuery.data?.originalDueDate;
  return (
    <div>
      {eligibleInvoiceDetailQuery.isSuccess ? (
        <>
          <div className="mb-4 text-sm">
            <Link
              to={
                isEmbedded
                  ? "/embed/dashboard?tab=eligible"
                  : "/home?tab=eligible"
              }
            >
              <span className="flex flex-row items-center">
                <ChevronLeftIcon className="h-4 w-4" />
                <span className="ml-2">Back to dashboard</span>
              </span>
            </Link>
          </div>
          {isEmbedded && (
            <div className="mb-6">
              <EarlyPaymentRequestHeaderRow pasId={pasId as string} />
            </div>
          )}

          <div className="flex flex-row flex-wrap gap-6">
            <div className="flex-1 flex flex-col gap-6">
              <Tabs type="card" items={tabItems} />
              {eligibleInvoiceDetailQuery.data.attachments ? (
                <DocumentsSection
                  attachments={eligibleInvoiceDetailQuery.data.attachments}
                />
              ) : null}
            </div>
            <div className="flex-1 mt-10">
              {shouldSummitAsIntentAsIs ? (
                <Alert>
                  <AlertTriangle className="h-4 w-4" />
                  <AlertDescription>
                    Claim pending approval in PAYAPPS.
                  </AlertDescription>
                </Alert>
              ) : (
                <div className={`rounded-lg p-6 bg-gray-800 text-white`}>
                  {isShowingDiscountReady ? (
                    <DiscountWidget
                      type={
                        eligibleInvoiceDetailQuery.data.discounts!.display_type
                      }
                      invoice={eligibleInvoiceShimedToInvoice}
                      discountConfiguration={
                        eligibleInvoiceDetailQuery.data.discounts!.payment_runs!
                      }
                      dynamicDiscountConfiguration={
                        eligibleInvoiceDetailQuery.data.discounts!
                          .dynamic_discounts
                      }
                      onChange={(discount) => {
                        setChosenDiscount(
                          discount as {
                            payrun_date: string;
                            discount: number;
                          }
                        );
                      }}
                    />
                  ) : (
                    <Alert>
                      <AlertTriangle className="h-4 w-4" />
                      <AlertDescription>
                        Discount not configured!
                      </AlertDescription>
                    </Alert>
                  )}
                </div>
              )}
              <div className="mt-4">
                <EligibleInvoiceRequestEarlyPaymentFooter
                  pasId={pasId as string}
                  discount={chosenDiscount}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export const EarlyPaymentRequestHeaderRow = ({ pasId }: { pasId: string }) => {
  return (
    <div className="flex flex-row flex-nowrap items-center justify-between font-normal text-sm">
      <div className="text-2xl font-bold">
        Early Payment Request - Eligible Invoice
      </div>
      <div>
        <span className="mr-2 text-gray-500">Status</span>
        <InvoiceStatusTag status={`Eligible`} />
      </div>
    </div>
  );
};
